<div class="contentheader" style="background-image: url(../../../assets/img/banner/2.jpg)">
    <div class="backdrop"></div>
    <div class="headercover">
        <div class="eventtitle" *ngIf="eventData">{{ eventData.title }}</div>
    </div>
</div>

<div class="promotion">
    <div class="promotioncover">
        <div class="contain">
            <div class="detail" *ngIf="eventData">
                <img [src]="eventData.img_url" />
                <div class="description">
                    <div [innerHTML]="eventData.desc"></div>
                </div>
                <div class="button" *ngIf="eventData.redirect_url">
                    <span (click)="goLink()">Check Out More</span>
                </div>
            </div>
            <div class="sidebar">
                <div class="download">
                    <div class="icon">
                        <img src="../../../assets/img/appicon.svg" />
                    </div>
                    <div class="title">WPT Global</div>
                    <div class="desc">Grab your 100% First Deposit Bonus up to $1,200 at the Online Home of the World Poker Tour</div>
                    <div class="button">
                        <span (click)="goDownload()">Download WPT Global</span>
                    </div>
                </div>
                <div class="list">
                    <div class="box" *ngFor="let item of otherData; index as index;" (click)="global.navigate('promotion?id=' + item.id)">
                        <div class="img" [style]="{'background-image' : 'url(' + item.img_url + ')'}"><img src="../../../assets/img/videocover.png" /></div>
                        <div class="content">
                            <div class="title">{{ item.title }}</div>
                            <div class="desc">{{ item.desc }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="clear-row"></div>
        </div>
    </div>
</div>