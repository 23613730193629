import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from "@angular/platform-browser";

import { ApiService } from 'src/app/service/api.service';
import { GlobalStore } from 'src/app/service/global.service';

// import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './challenge.page.html',
  styleUrls: ['./challenge.page.scss']
})
export class ChallengePage implements OnInit {
  
  constructor(
    private ApiService: ApiService,
    private route: ActivatedRoute,
    public router: Router,
    private sanitizer: DomSanitizer,
    public global: GlobalStore
    // public translate: TranslateService,
  ) {
  }

  'challengeData' : any = [];
  'rankData' : any = [];
  'userChallengeData' : any = [];
  'currentData' : any = null;
  'currentChallenge' : any = null;
  'currentRank' : any = null;

  ngOnInit(): void {

    this.global.getUserInfo(() =>
    {
      this.getRankList();
      this.getChallengeList();
    }, () =>
    {
      window.location.href = '/login'
    });
  }

  getRankList()
  {
    this.ApiService.getRankList({
      'per_page' : 100,
    }).subscribe(
      (res: any) => {
        if(res.data && res.data.length > 0)
        {
          this.rankData = JSON.parse(JSON.stringify(res.data));
          let index = this.rankData.findIndex((x:any) => x.name === this.global.userInfo.rank.name);
          if(index > -1)
          {
            this.currentRank = JSON.parse(JSON.stringify(this.rankData[index]));
          }
        }
      },
      (error:any) =>
      {
        console.warn(error);
      }
    );
  }
  getChallengeList()
  {
    this.challengeData = [];
    let dataArr : any = [];
    this.ApiService.getChallengeList({
      'sort[0]' : '-level',
    }).subscribe(
      (res: any) => {
        if(res.data && res.data.length > 0)
        {
          let arr = JSON.parse(JSON.stringify(res.data));
          arr.map((item:any) =>
          {
            let dataObj = JSON.parse(JSON.stringify(item));
            dataArr.push(dataObj);
          });

          this.challengeData = dataArr;
          this.getCurrentQuizState();
        }
      },
      (error:any) =>
      {
        console.warn(error);
      }
    );
  }
  getCurrentQuizState()
  {
    this.userChallengeData = [];
    this.ApiService.getActiveChallenge().subscribe(
      (res: any) => {
        if(res.data)
        {
          this.currentData = JSON.parse(JSON.stringify(res.data));
          if(this.currentData && this.currentData.length > 0)
          {
            this.currentData.percentage = (res.data.completed_qnas / res.data.total_qnas) * 100;
            let arr : any = [];
            let index = this.challengeData.findIndex((x:any) => x.id === this.currentData.current_challange_id);
            if(index > -1)
            {
              this.currentChallenge = JSON.parse(JSON.stringify(this.challengeData[index]));
              // if first 4
              if(index <= 3)
              {
                this.challengeData.map((item:any, epos:number) => 
                {
                  if(epos <= 3)
                  {
                    arr.push({
                      'id' : item.id,
                      'title' : item.title,
                      'status' : item.status,
                      'level' : item.level,
                      'progress' : epos > index ? 'incomplete' : (epos < index ? 'completed' : 'ongoing') 
                    });
                  }
                })
              }
              else
              {
                // if not last
                if(index + 1 < this.challengeData.length)
                {
                  console.warn(2)
                  this.challengeData.map((item:any, epos:number) => 
                  {
                    if(epos > index - 3 && epos <= index + 1)
                    {
                      arr.push({
                        'id' : item.id,
                        'title' : item.title,
                        'status' : item.status,
                        'level' : item.level,
                        'progress' : epos > index ? 'incomplete' : (epos < index ? 'completed' : 'ongoing') 
                      });
                    }
                  })
                }
                else
                {
                  console.warn(3)
                  this.challengeData.map((item:any, epos:number) => 
                  {
                    if(epos >= index - 3)
                    {
                      arr.push({
                        'id' : item.id,
                        'title' : item.title,
                        'status' : item.status,
                        'level' : item.level,
                        'progress' : epos > index ? 'incomplete' : (epos < index ? 'completed' : 'ongoing') 
                      });
                    }
                  })
                }
              }
            }
            this.userChallengeData = arr;
          }
          else
          {
            let arr : any = [];
            this.challengeData.map((item:any) =>
            {
              arr.push({
                'id'      : item.id,
                'title'   : item.title,
                'status'  : item.status,
                'level'   : item.level,
                'progress': 'completed' 
              });
            })

            this.userChallengeData = arr.slice(-4);
          }
        }
      },
      (error:any) =>
      {
        console.warn(error);
      }
    );
  }
  

}

