import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// module
import { SwiperModule } from 'swiper/angular';
import { NgMarqueeModule } from 'ng-marquee';

// component
import { VideoPlayer } from './components/videoplayer/videoplayer.component';
import { WptHeader } from './components/header/header.component';
import { TabMenu } from './components/tabmenu/tabmenu.component';
import { EventBox } from './components/event/event.component';
import { Areacode } from './components/areacode/areacode.component';

// other
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
SwiperCore.use([Navigation, Pagination, Autoplay]);


@NgModule({
  imports: [
    CommonModule,
    SwiperModule,
    FormsModule,
    NgMarqueeModule
  ],
  exports: [
    SwiperModule,
    FormsModule,
    NgMarqueeModule,

    VideoPlayer,
    WptHeader,
    TabMenu,
    EventBox,
    Areacode
  ],
  declarations: [
    VideoPlayer,
    WptHeader,
    TabMenu,
    EventBox,
    Areacode
  ],
  providers: [
  ],
})
export class SharedModule { }
