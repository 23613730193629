<div class="event-box">
    <div class="eventcover">
        <div class="leftcol">
            <div class="title">
                <div class="titlecover">
                    <div>October</div>
                    <span>Hot Events</span>
                </div>
            </div>
            <div class="desc">
                Check out our latest events and promotions.
                <br />
                Join us now and don't miss out any latest news.
            </div>
            <div class="btn">
                <span>View All Event</span>
            </div>
        </div>
        <div class="rightcol">
            <div class="box" *ngFor="let item of listData; index as index;">
                <div class="img" [style]="{'background-image' : 'url(' + item.img_url + ')'}">
                    <img src="../../../assets/img/videocover.png" />
                </div>
                <div class="text">
                    <div class="title">{{ item.title }}</div>
                    <!-- <div class="desc">{{ item.desc }}</div> -->
                </div>
            </div>
        </div>
        <div class="btn">
            <span>View All Event</span>
        </div>
    </div>
</div>