import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ApiService } from 'src/app/service/api.service';
import { GlobalStore } from 'src/app/service/global.service';

// import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './forget.page.html',
  styleUrls: ['./forget.page.scss']
})
export class ForgetPage implements OnInit {
  
  constructor(
    private ApiService: ApiService,
    private route: ActivatedRoute,
    public router: Router,
    public global: GlobalStore,
    // public translate: TranslateService,
  ) {
  }

  'tabIndex' : number = 0;
  'formData'   : any = {
    'email'      : '',
    'mobile'     : '',
    'yzm'        : '',
    'password'   : '',
    'conpassword': ''
  };
  'otpText' : string = 'Get OTP';
  'otpTimer' : any = null;
  'otpCountdown' : number = 60;
  'otpLoader' : boolean = false;
  'loader' : boolean = false;

  ngOnInit(): void {

  }

  selectTab(index:number)
  {
    this.tabIndex = index;
  }

  getYzm()
  {
    this.otpLoader = true;
    this.ApiService.getRegisterOTP({
      'receiver_type' : 'phone',
      'phone_number': this.formData.mobile
    }).subscribe(
      (res: any) => {
        if(res.data)
        {
          this.global.dialog = {
            'mode'  : 'success',
            'button': 'Confirm',
            'list'  : [`OTP has been SMS to your mobile, if you didn't receive after 1 minute, please resend.`]
          };
          this.otpLoader = false;
          this.countDown();
        }
      },
      (error:any) =>
      {
        this.otpLoader = false;
        this.global.dialog = {
          'mode'  : 'error',
          'button': 'Confirm',
          'list'  : [`${error.error.message}`]
        };
      }
    );
  }
  countDown()
  {
    clearInterval(this.otpTimer);
    this.otpTimer = null;
    this.otpText = `${this.otpCountdown} Sec`;

    this.otpTimer = setInterval(() => 
    {
      if(this.otpCountdown > 0)
      {
        this.otpCountdown --;
        
        this.otpText = `${this.otpCountdown} Sec`;
      }
      else
      {
        this.otpCountdown = 60;

        this.otpText = 'Get OTP';

        clearInterval(this.otpTimer);
        this.otpTimer = null;
      }
    }, 1000)
  }

  login()
  {
    let form : any = {
      'password'   : this.formData.password
    }
    if(this.formData.email)
    {
      form.identifier = this.formData.email;
    }
    else
    {
      form.identifier = this.formData.mobile
    }
    
    this.ApiService.loginUser(form).subscribe(
      (res: any) => {
        if(res.data)
        {
          localStorage.setItem('token', res.data.token || '');
          this.global.dialog = {
            'mode'  : 'success',
            'button': 'Confirm',
            'list'  : [`Register Success, Welcome!`]
          };
          setTimeout(() =>
          {
            window.location.href = '/';
          }, 1200)
        }
      },
      (error:any) =>
      {
        this.loader = false;
        this.global.dialog = {
          'mode'  : 'error',
          'button': 'Confirm',
          'list'  : [`${error.error.message}`]
        };
      }
    );
  }
  

}

