import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ApiService } from 'src/app/service/api.service';
import { GlobalStore } from 'src/app/service/global.service';

// import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './register.page.html',
  styleUrls: ['./register.page.scss']
})
export class RegisterPage implements OnInit {
  
  constructor(
    private ApiService: ApiService,
    public global: GlobalStore,
    private route: ActivatedRoute,
    public router: Router
    // public translate: TranslateService,
  ) {
  }

  'tabIndex' : number = 0;
  'formData'   : any = {
    'areacode'   : '',
    'email'      : '',
    'mobile'     : '',
    'yzm'        : '',
    'username'   : '',
    'name'       : '',
    'password'   : ''
  };
  'otpText' : string = 'Get OTP';
  'otpTimer' : any = null;
  'otpCountdown' : number = 60;
  'otpLoader' : boolean = false;
  'loader' : boolean = false;

  'dialSelect' : boolean = false;
  'selectedCode' : any = null;

  ngOnInit(): void {

  }

  selectTab(index:number)
  {
    this.tabIndex = index;
  }

  openCodeList()
  {
    this.dialSelect = true;
  }
  selectDialCode(data:any)
  {
    this.selectedCode = JSON.parse(JSON.stringify(data));
    this.formData.areacode = `${this.selectedCode.code} ${this.selectedCode.dial_code}`;
    this.dialSelect = false;
  }

  getYzm()
  {
    this.otpLoader = true;
    this.ApiService.getRegisterOTP({
      'receiver_type' : 'phone',
      'phone_number': this.formData.mobile
    }).subscribe(
      (res: any) => {
        if(res.data)
        {
          this.global.dialog = {
            'mode'  : 'success',
            'button': 'Confirm',
            'list'  : [`OTP has been SMS to your mobile, if you didn't receive after 1 minute, please resend.`]
          };
          this.otpLoader = false;
          this.countDown();
        }
      },
      (error:any) =>
      {
        this.otpLoader = false;
        this.global.dialog = {
          'mode'  : 'error',
          'button': 'Confirm',
          'list'  : [`${error.error.message}`]
        };
      }
    );
  }
  countDown()
  {
    clearInterval(this.otpTimer);
    this.otpTimer = null;
    this.otpText = `${this.otpCountdown} Sec`;

    this.otpTimer = setInterval(() => 
    {
      if(this.otpCountdown > 0)
      {
        this.otpCountdown --;
        
        this.otpText = `${this.otpCountdown} Sec`;
      }
      else
      {
        this.otpCountdown = 60;

        this.otpText = 'Get OTP';

        clearInterval(this.otpTimer);
        this.otpTimer = null;
      }
    }, 1000)
  }

  register()
  {
    this.loader = true;
    let data : any = {
      'username' : this.formData.username,
      'name'     : this.formData.name,
      'password' : this.formData.password
    }

    if(this.selectedCode)
    {
      data.area_code = this.selectedCode.value
    }

    if(this.tabIndex === 0)
    {
      data.phone = `${this.formData.mobile}`;
      data.otp = this.formData.yzm;
    }
    else
    {
      data.email = this.formData.email;
    }
    this.ApiService.registerUser(data).subscribe(
      (res: any) => {
        if(res.data)
        {
          this.login();
        }
      },
      (error:any) =>
      {
        this.loader = false;
        this.global.dialog = {
          'mode'  : 'error',
          'button': 'Confirm',
          'list'  : [`${error.error.message}`]
        };
      }
    );
  }
  login()
  {
    let form : any = {
      'password'   : this.formData.password
    }
    if(this.formData.email)
    {
      form.identifier = this.formData.email;
    }
    else
    {
      form.identifier = this.formData.mobile
    }
    
    this.ApiService.loginUser(form).subscribe(
      (res: any) => {
        if(res.data)
        {
          localStorage.setItem('token', res.data.token || '');
          this.updateNewUserImage();
        }
      },
      (error:any) =>
      {
        this.loader = false;
        this.global.dialog = {
          'mode'  : 'error',
          'button': 'Confirm',
          'list'  : [`${error.error.message}`]
        };
      }
    );
  }
  updateNewUserImage()
  {
    let profilenum = Math.floor(Math.random() * 10) + 1; 
    let covernum = Math.floor(Math.random() * 10) + 1;

    let cover = `images/user/template/cover/c${covernum}.jpg`;
    let profile = `images/user/template/profile/p${profilenum}.png`;
    this.ApiService.updateUserInfo({
      'cover_img_url'  : cover,
      'profile_img_url': profile,
      'username'       : this.formData.username,
      'name'           : this.formData.name
    }).subscribe(
      (res: any) => {
        if(res.data)
        {
          this.loader = false;
          this.global.dialog = {
            'mode'  : 'success',
            'list'  : [`Register Success, Welcome!`]
          };
          
          setTimeout(() =>
          {
            window.location.href = '/';
          }, 1000)
        }
      },
      (error:any) =>
      {
        this.loader = false;
        this.global.dialog = {
          'mode'  : 'error',
          'button': 'Confirm',
          'list'  : [`${error.error.message}`]
        };
      }
    );
  }

}

