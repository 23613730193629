import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from "@angular/platform-browser";

import { ApiService } from 'src/app/service/api.service';
import { GlobalStore } from 'src/app/service/global.service';

// import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './promotion.page.html',
  styleUrls: ['./promotion.page.scss']
})
export class PromotionPage implements OnInit {
  
  constructor(
    private ApiService: ApiService,
    private route: ActivatedRoute,
    public router: Router,
    private sanitizer: DomSanitizer,
    public global: GlobalStore
    // public translate: TranslateService,
  ) {
    this.route.queryParams
      .subscribe((params:any) => 
      {
        if(params.id)
        {
          this.eventId = params.id;
          this.getEventDetail();
          this.getEventList();
        }
      }
    );
  }

  'eventId' : string = '';
  'eventData' : any = null;
  'otherData' : any = [];
  'style' : any = `
    <style>
      p {display:block; position:relative; width:100%; font-size:14px; line-height:22px; padding:0px; margin:0px 0px 10px 0px;}
      p:last-child {margin:0px;}
      ul {display:block; position:relative; margin:0px; padding:0px 0px 0px 28px;}
      ul li p{margin:0px;}
    </style>
  `;

  ngOnInit(): void {

    
  }

  
  getEventDetail()
  {
    this.eventData = null;
    this.ApiService.getHotEventDetail({'id' : this.eventId}).subscribe(
      (res: any) => {
        if(res.data)
        {
          this.eventData = JSON.parse(JSON.stringify(res.data));
          this.eventData.desc = this.sanitizer.bypassSecurityTrustHtml(`${this.style}${res.data.description}`);
        }
      },
      (error:any) =>
      {
        console.warn(error);
      }
    );
  }
  getEventList()
  {
    this.otherData = [];
    let dataArr : any = [];
    this.ApiService.getHotEventList({}).subscribe(
      (res: any) => {
        if(res.data && res.meta)
        {
          if(res.data && res.data.length > 0)
          {
            let arr = JSON.parse(JSON.stringify(res.data));
            arr.map((item:any) =>
            {
              let dataObj = JSON.parse(JSON.stringify(item));
              if(item.id !== this.eventId)
              {
                dataArr.push(dataObj);
              }
            });
            
            this.otherData = this.global.shuffleArray(dataArr);
            this.otherData.length = 2;
          }
        }
      },
      (error:any) =>
      {
        console.warn(error);
      }
    );
  }

  goLink()
  {
    window.open(`${this.eventData.redirect_url}`);
  }
  goDownload()
  {
    window.open(this.global.downloadUrl);
  }
  

}

