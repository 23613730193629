<div class="contentheader" style="background-image: url(../../../assets/img/banner/1.jpg)">
    <div class="backdrop"></div>
    <div class="headercover">
        <div class="text">
            <div class="title">Watch WPT</div>
            <div class="desc">Enjoy watching our WPT's previous tournament videos and on-live WPT tournament.</div>
        </div>
    </div>
</div>

<div class="videos">
    <div class="videoscover">
        <div class="top">
            <div class="main">
                <div class="box" (click)="global.navigate('watch?id=' + mainData.id)">
                    <div class="img" [style]="{'background-image' : 'url(' + mainData.img_url + ')'}"><img src="../../../assets/img/videocover.png" /></div>
                    <div class="content">
                        <div class="title">{{ mainData.title }}</div>
                        <div class="desc">{{ mainData.desc }}</div>
                    </div>
                </div>
            </div>
            <div class="sub">
                <div class="box" *ngFor="let item of subData; index as index;" (click)="global.navigate('watch?id=' + item.id)">
                    <div class="img" [style]="{'background-image' : 'url(' + item.img_url + ')'}"><img src="../../../assets/img/videocover.png" /></div>
                    <div class="content">
                        <div class="title">{{ item.title }}</div>
                        <div class="desc">{{ item.desc }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="btm">
            <div class="box" *ngFor="let item of otherData; index as index;" (click)="global.navigate('watch?id=' + item.id)">
                <div class="img" [style]="{'background-image' : 'url(' + item.img_url + ')'}"><img src="../../../assets/img/videocover.png" /></div>
                <div class="content">
                    <div class="title">{{ item.title }}</div>
                    <div class="desc">{{ item.desc }}</div>
                </div>
            </div>
        </div>
    </div>
</div>