import { Component, OnInit, Input } from '@angular/core';
import { GlobalStore } from 'src/app/service/global.service';
import { ActivatedRoute, Router } from '@angular/router';

import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'tab-menu',
  templateUrl: './tabmenu.component.html',
  styleUrls: ['./tabmenu.component.scss'],
})

export class TabMenu implements OnInit {

  constructor(
    public global : GlobalStore,
    private route: ActivatedRoute,
    public router: Router,
    private ApiService: ApiService
  ) { }

  'dataList' : any  = [];
  'tabIndex' : number = 0;
  'pagiData' : any = [];
  'pageData' : any = null;
  'loader'   : boolean = false;
  'liveData' : any = null;
  'detailData' : any = null;

  ngOnInit(): void {
    
    this.getVideoList();
  }

  getVideoList()
  {
    this.dataList = [];
    let dataArr : any = [];
    this.ApiService.getVideoList({
      'per_page': 8,
      'page'    : this.pageData ? this.pageData.current_page: 1,
      'sort[0]' : '-sequence',
      'sort[1]' : '-id',
    }).subscribe(
      (res: any) => {
        if(res.data && res.meta)
        {
          if(res.data && res.data.length > 0)
          {
            let arr = JSON.parse(JSON.stringify(res.data));
            arr.map((item:any) =>
            {
              let dataObj = JSON.parse(JSON.stringify(item));
              dataObj.desc = item.description ? item.description.replace(/<[^>]+>/g, '') : '';
              dataObj.createddate = item.created_at.split('T')[0];
              dataArr.push(dataObj);
            });
            this.dataList = dataArr;

            this.pageData = JSON.parse(JSON.stringify(res.meta));
            this.loader = false;
          }
        }
        else
        {
          this.loader = false;
        }
      },
      (error:any) =>
      {
        console.warn(error);
        this.loader = false;
      }
    );
  }
  prev()
  {
    if(this.pageData.current_page > 1)
    {
      this.pageData.current_page = this.pageData.current_page - 1;
      this.loader = true;
      this.getVideoList();
    }
  }
  next()
  {
    if(this.pageData.current_page < this.pageData.last_page)
    {
      this.pageData.current_page = this.pageData.current_page + 1;
      this.loader = true;
      this.getVideoList();
    }
  }
  selectTab(num:number)
  {
    this.tabIndex = num;
    if(num === 0)
    {
      this.liveData = '';
      this.pageData = null;
      this.loader = true;
      this.getVideoList();
    }
    else
    {
      this.dataList = [];
      this.loader = true;
      this.getStream();
    }
  }
  getStream()
  {
    this.liveData = '';
    this.ApiService.getStreamList({
      'filter[status]': 'active',
    }).subscribe(
      (res: any) => {
        if(res.data && res.data.length > 0)
        {
          let dataObj = JSON.parse(JSON.stringify(res.data[0]));
          this.liveData = {
            'url' : {
              'flv' : dataObj.flv_play_url,
              'hls' : dataObj.hls_play_url,
              'mp4' : ''
            },
            'id'      : dataObj.id,
            'img'     : dataObj.img_url,
            'type'    : 'live'
          }
          this.loader = false;
        }
        else
        {
          this.loader = false;
        }
      },
      (error:any) =>
      {
        console.warn(error);
        this.loader = false;
      }
    );
  }
  selectVideo(item:any)
  {
    let data : any = JSON.parse(JSON.stringify(item));
    this.detailData = {
      'url' : {
        'flv' : '',
        'hls' : '',
        'mp4' : data.output_mp4_url
      },
      'id'      : data.id,
      'img'     : data.img_url,
      'type'    : 'video'
    }
  }
  closeVideo()
  {
    this.detailData = null;
  }

}