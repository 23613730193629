<div class="header" [class]="{'scrolled' : isScroll, 'openned' : openNav}">
    <div class="headercover">
        <div class="logo" (click)="goHome()">
            <img class="light" src="../../../assets/img/wpt-logo-white.png" />
            <img class="dark" src="../../../assets/img/wpt-logo-black.png" />
        </div>
        <div class="menu">
            <div class="link">
                <div [class]="{'active' : router.url === '/'}" (click)="global.navigate('/')">Homepage</div>
                <div [class]="{'active' : router.url.includes('/videos')}" (click)="global.navigate('/videos')">Watch WPT</div>
                <div [class]="{'active' : router.url.includes('/events')}" (click)="global.navigate('/events')">Events & News</div>
                <div [class]="{'active' : router.url.includes('/challenge')}" (click)="goChallenge()">My Challenge</div>
            </div>
            <div class="user" *ngIf="!loading && !userData" (click)="global.navigate('/login')">
                <div><i class="iconfont icon-user"></i></div>
            </div>
            <div class="profile" *ngIf="!loading && userData">
                <div class="profilecover" (click)="toggleShortcut($event)">
                    <div class="image" [style]="{'background-image' : 'url(' + userData.profile_img_url + ')'}"></div>
                    <div class="rank"><img src="../../../assets/img/rank/1.png" /></div>
                </div>
                <div class="profiledetail" [class]="{'active' : openShortcut}">
                    <div class="box"><i class="iconfont icon-profile"></i>Profile</div>
                    <div class="box"><i class="iconfont icon-bagpack"></i>Bagpack</div>
                    <div class="box" (click)="global.logout()"><i class="iconfont icon-logout"></i>Logout</div>
                </div>
            </div>
            <div class="download" (click)="goDownload()">
                <div>Download WPTG</div>
            </div>
        </div>
        <div class="own" *ngIf="!loading && !userData" (click)="global.navigate('/login')">
            <div><i class="iconfont icon-user"></i></div>
        </div>
        <div class="profile" *ngIf="!loading && userData">
            <div class="profilecover" (click)="toggleShortcut($event)">
                <div class="image" [style]="{'background-image' : 'url(' + userData.profile_img_url + ')'}"></div>
                <div class="rank"><img src="../../../assets/img/rank/1.png" /></div>
            </div>
            <div class="profiledetail" [class]="{'active' : openShortcut}">
                <div class="box"><i class="iconfont icon-profile"></i>Profile</div>
                <div class="box"><i class="iconfont icon-bagpack"></i>Bagpack</div>
                <div class="box" (click)="global.logout()"><i class="iconfont icon-logout"></i>Logout</div>
            </div>
        </div>
        <div class="nav" (click)="openNav = !openNav">
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
</div>
<div class="headerspace"></div>

<div class="navbox" [class]="{'active' : openNav}">
    <div class="title">
        <span>Welcome to</span><div>WPT Global</div>
    </div>
    <div class="menu">
        <span>Homepage</span>
        <span>Tournament</span>
        <span>Videos</span>
        <span>Events & News</span>
        <span>My Challenge</span>
    </div>
</div>