import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ApiService } from 'src/app/service/api.service';
import { GlobalStore } from 'src/app/service/global.service';

// import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss']
})
export class LoginPage implements OnInit {
  
  constructor(
    private ApiService: ApiService,
    private route: ActivatedRoute,
    public router: Router,
    public global: GlobalStore,
    // public translate: TranslateService,
  ) {
  }

  'tabIndex' : number = 0;
  'formData'   : any = {
    'identifier': '',
    'password'  : '',
    'areacode'  : '',
    'mobile'    : '',
    'yzm'       : '',
  };
  'otpText' : string = 'Get OTP';
  'otpTimer' : any = null;
  'otpCountdown' : number = 60;
  'otpLoader' : boolean = false;
  'loader' : boolean = false;

  'dialSelect' : boolean = false;
  'selectedCode' : any = null;

  ngOnInit(): void {

  }

  selectTab(index:number)
  {
    this.tabIndex = index;
  }

  openCodeList()
  {
    this.dialSelect = true;
  }
  selectDialCode(data:any)
  {
    this.selectedCode = JSON.parse(JSON.stringify(data));
    this.formData.areacode = `${this.selectedCode.code} ${this.selectedCode.dial_code}`;
    this.dialSelect = false;
  }

  getYzm()
  {
    this.otpLoader = true;
    this.ApiService.getRegisterOTP({
      'receiver_type' : 'phone',
      'phone_number': this.formData.mobile
    }).subscribe(
      (res: any) => {
        if(res.data)
        {
          this.global.dialog = {
            'mode'  : 'success',
            'button': 'Confirm',
            'list'  : [`OTP has been SMS to your mobile, if you didn't receive after 1 minute, please resend.`]
          };
          this.otpLoader = false;
          this.countDown();
        }
      },
      (error:any) =>
      {
        this.otpLoader = false;
        this.global.dialog = {
          'mode'  : 'error',
          'button': 'Confirm',
          'list'  : [`${error.error.message}`]
        };
      }
    );
  }
  countDown()
  {
    clearInterval(this.otpTimer);
    this.otpTimer = null;
    this.otpText = `${this.otpCountdown} Sec`;

    this.otpTimer = setInterval(() => 
    {
      if(this.otpCountdown > 0)
      {
        this.otpCountdown --;
        
        this.otpText = `${this.otpCountdown} Sec`;
      }
      else
      {
        this.otpCountdown = 60;

        this.otpText = 'Get OTP';

        clearInterval(this.otpTimer);
        this.otpTimer = null;
      }
    }, 1000)
  }

  login()
  {
    this.loader = true;
    if(this.tabIndex === 0)
    {
      let form : any = {
        'identifier' : this.formData.identifier,
        'password'   : this.formData.password
      }
      
      this.ApiService.loginUser(form).subscribe(
        (res: any) => {
          if(res.data)
          {
            this.loader = false;
            localStorage.setItem('token', res.data.token || '');
            this.global.dialog = {
              'mode'  : 'success',
              'list'  : [`Login Successfully, Welcome Back!`]
            };
            setTimeout(() =>
            {
              window.location.href = '/';
            }, 1200)
          }
        },
        (error:any) =>
        {
          this.loader = false;
          this.global.dialog = {
            'mode'  : 'error',
            'button': 'Confirm',
            'list'  : [`${error.error.message}`]
          };
        }
      );
    }
    else
    {
      let data = {
        'area_code': this.selectedCode.value,
        'phone'    : this.formData.mobile,
        'otp'      : this.formData.yzm
      }
      this.ApiService.otpLogin(data).subscribe(
        (res: any) => {
          if(res.data)
          {
            this.loader = false;
            
            this.global.dialog = {
              'mode'  : 'success',
              'list'  : [`Login Successfully, Welcome Back!`]
            };
            
            localStorage.setItem('token', res.data.token || '');
            setTimeout(() =>
            {
              let decodeText = decodeURIComponent(this.router.url);
              if(decodeText.includes('?from='))
              {
                window.location.href = decodeText.split('?from=')[1];
              }
              else
              {
                window.location.href = '/';
              }
            }, 1000)
          }
        },
        (error:any) =>
        {
          this.loader = false;
          this.global.dialog = {
            'mode'  : 'error',
            'button': 'Confirm',
            'list'  : [`${error.error.message}`]
          };
        }
      );
    }
  }
  

}

