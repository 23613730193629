import { Injectable } from '@angular/core';
import { ApiService } from '../service/api.service';

@Injectable()
export class GlobalStore {


  constructor(
    public ApiService: ApiService
  ) {
  }
    
  'downloadUrl' : string = 'https://wptglobal.com/poker-download?referralCode=6DAE5C';
  'dialog' : any = null;
  'userInfo' : any = null;
  'loginLoader' : boolean = false;
  'share' : any = null;
  
  // user function
  navigate(route:string)
  {
    window.location.href = `${route}`;
  }

  logout()
  {
    this.dialog = {
      'mode'  : 'success',
      'list'  : [`Account logout Successfully`]
    }
    setTimeout(() => {
      localStorage.removeItem('token');
      this.userInfo = null;
      window.location.href = '/'
    }, 1000);
  }

  getUserInfo(callback?: (res: any) => void, errorback?: (res: any) => void)
  {
    this.loginLoader = true;
    this.ApiService.getUserInfo().subscribe(
      (res: any) => {
        if(res.data)
        {
          let data = JSON.parse(JSON.stringify(res.data));
          
          this.loginLoader = false;
          this.userInfo = data;
          callback && callback(res);
        }
      },
      (error:any) =>
      {
        this.userInfo = null;
        this.loginLoader = false;
        localStorage.removeItem('token');
        errorback && errorback(error);
      }
    );
  }

  shuffleArray(array:any) {
    for (var i = array.length - 1; i > 0; i--) { 
   
        // Generate random number 
        var j = Math.floor(Math.random() * (i + 1));
                   
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
       
    return array;
  }
}