<div class="tab-menu">
    <div class="tabcover">
        <div class="title">Check Out Our</div>
        <div class="desc">Live Streams & Videos Too</div>
        <div class="tab">
            <div class="list" *ngIf="dataList && dataList && !loader && tabIndex === 0">
                <div class="box" *ngFor="let item of dataList; index as index;" (click)="selectVideo(item)">
                    <div class="image" [style]="{'background-image' : 'url(' + item.img_url + ')'}">
                        <img src="../../../assets/img/videocover.png">
                        <span><i class="iconfont icon-play"></i></span>
                    </div>
                    <div class="content">
                        <div class="title">
                            <div>{{ item.title }}</div>
                            <span>{{ item.desc }}</span>
                        </div>
                        <div class="detail">
                            <div>{{ item.createddate }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="listloader" *ngIf="loader && tabIndex === 0">
                <div class="loadbox"><div><img src="../../../assets/img/videocover.png" /></div><span></span></div>
                <div class="loadbox"><div><img src="../../../assets/img/videocover.png" /></div><span></span></div>
                <div class="loadbox"><div><img src="../../../assets/img/videocover.png" /></div><span></span></div>
                <div class="loadbox"><div><img src="../../../assets/img/videocover.png" /></div><span></span></div>
                <div class="loadbox"><div><img src="../../../assets/img/videocover.png" /></div><span></span></div>
                <div class="loadbox"><div><img src="../../../assets/img/videocover.png" /></div><span></span></div>
                <div class="loadbox"><div><img src="../../../assets/img/videocover.png" /></div><span></span></div>
                <div class="loadbox"><div><img src="../../../assets/img/videocover.png" /></div><span></span></div>
            </div>
            <div class="live" *ngIf="liveData && tabIndex === 1">
                <video-player [data]="liveData"></video-player>
            </div>
            <div class="liveloader" *ngIf="loader && tabIndex === 1">
                <img src="../../../assets/img/videocover.png" />
            </div>
            <div class="empty" *ngIf="(!loader && tabIndex === 0 && dataList.length === 0) || (!loader && tabIndex === 1 && liveData.length === 0)">
                No Data
            </div>
        </div>
    </div>
</div>

<div class="videopop" [class]="{'active' : detailData}">
    <div class="overlay" (click)="closeVideo()"></div>
    <div class="popcover">
        <img src="../../../assets/img/videocover.png" />
        <div class="contain">
            <video-player [data]="detailData"></video-player>
        </div>
    </div>
</div>