import { Component, OnInit, Input } from '@angular/core';
import { GlobalStore } from 'src/app/service/global.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'wpt-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})

export class WptHeader implements OnInit {

  @Input()
  set user(val:any)
  {
    this.userData = val;
  }
  @Input()
  set loader(val:boolean)
  {
    this.loading = val;
  }

  constructor(
    public global : GlobalStore,
    private route: ActivatedRoute,
    public router: Router
  ) {}

  'isScroll' : boolean = false;
  'openNav' : boolean = false;
  'userData': any = null;
  'loading' : boolean = false;
  'openShortcut' : boolean = false;

  ngOnInit(): void {
    
    window.addEventListener('scroll', () =>
    {
      let space : any = document.querySelector('.headerspace');
      if(space)
      {
        let data = space.getBoundingClientRect();
        this.isScroll = data.top < -80;
      }
    });
    window.addEventListener('click', () =>
    {
      this.openShortcut = false;
    })
  }

  toggleShortcut(event:any)
  {
    event.stopPropagation();
    this.openShortcut = !this.openShortcut;
  }

  goHome()
  {
    window.location.href = `/`;
  }
  goDownload()
  {
    window.open(this.global.downloadUrl);
  }

  goChallenge()
  {
    if(this.global.userInfo)
    {
      window.location.href = '/challenge';
    }
    else
    {
      window.location.href = `/login?from=/challenge`;
    }
  }

}