import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { DomSanitizer } from "@angular/platform-browser";

import { ApiService } from 'src/app/service/api.service';
import { GlobalStore } from 'src/app/service/global.service';

import { SwiperOptions } from 'swiper';

@Component({
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss']
})
export class HomePage implements OnInit {
  
  'swiperConfig'   : SwiperOptions = {
    'slidesPerView': 1,
    'spaceBetween' :  0
  };
  'bannerData' : any = [];
  'selectedIndex' : number = 0;
  'banner' : any = null;
  'style' : any = `
    <style>
      .textcover .title span, .floatcover .title span { color:#dad500; }
    </style>
  `;

  'promoData' : any = [];
  'isMorePromo' : boolean = false;
  'monthData' : any = ['January', 'Febuary', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  'month' : string = '';

  'dataList' : any  = [];
  'listLoader' : boolean = false;
  

  constructor(
    private metaService: Meta,
    private titleService: Title,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    public router: Router,
    public global: GlobalStore,
    private ApiService: ApiService
  ) {
    // this.metaService.updateTag({ name: 'og:title', content: '扑克坊 Pokefang - 直播首页' });
    // this.titleService.setTitle('扑克坊 Pokefang - 直播首页');
  }

  ngOnInit(): void {
    this.getBanner();

    let date = new Date();
    let thismonth = date.getMonth();
    this.month = this.monthData[thismonth];

    this.getPromotion();
    this.getVideoList();
  }

  getBanner()
  {
    this.ApiService.getBannerList({}).subscribe(
      (res: any) => {
        if(res.banners && res.banners.length > 0)
        {
          let finalArr : any =[];
          let arr = JSON.parse(JSON.stringify(res.banners));
          arr.map((item:any) =>
          {
            let dataObj = JSON.parse(JSON.stringify(item));
            dataObj.text = this.sanitizer.bypassSecurityTrustHtml(`${this.style}${item.title}`);

            finalArr.push(dataObj);
          })

          this.bannerData = finalArr;
        }
      },
      (error:any) =>
      {
        console.warn(error);
      }
    );
  }
  startSwiper(event:any)
  {
    this.banner = event;
  }
  selectPag(index:number)
  {
    this.selectedIndex = index;
    this.banner.slideTo(index);
  }
  goDownload()
  {
    window.open(this.global.downloadUrl);
  }
  goLink(link:string)
  {
    window.location.href = `${link}`;
  }
  goBannerLink(item:any)
  {
    if(!item.title && item.redirect_url)
    {
      window.location.href = `${item.redirect_url}`;
    }
  }
  slideChange(event:any)
  {
    this.selectPag(1);
  }

  getPromotion()
  {
    this.promoData = [];
    let dataArr : any = [];
    this.ApiService.getHotEventList({}).subscribe(
      (res: any) => {
        if(res.data && res.meta)
        {
          if(res.data && res.data.length > 0)
          {
            let arr = JSON.parse(JSON.stringify(res.data));
            arr.map((item:any) =>
            {
              let dataObj = JSON.parse(JSON.stringify(item));
              dataArr.push(dataObj);
            });

            if(dataArr.length > 3)
            {
              dataArr.length = 3;
              this.isMorePromo = true;
            }
            
            this.promoData = dataArr;
          }
        }
      },
      (error:any) =>
      {
        console.warn(error);
      }
    );
  }
  goDetail(id:string)
  {
    window.location.href = `/promotion?id=${id}`
  }
  goPromotion()
  {
    window.location.href = `/events`;
  }

  getVideoList()
  {
    this.listLoader = true;
    this.dataList = [];
    let dataArr : any = [];
    this.ApiService.getVideoList({
      'per_page': 8,
      'sort[0]' : '-sequence',
      'sort[1]' : '-id',
    }).subscribe(
      (res: any) => {
        if(res.data && res.data.length > 0)
        {
          let arr = JSON.parse(JSON.stringify(res.data));
          arr.map((item:any) =>
          {
            let dataObj = JSON.parse(JSON.stringify(item));
            dataObj.desc = item.description ? item.description.replace(/<[^>]+>/g, '') : '';
            dataObj.createddate = item.created_at.split('T')[0];
            dataArr.push(dataObj);
          });
          this.dataList = dataArr;
          this.listLoader = false
        }
        else
        {
          this.listLoader = false
        }
      },
      (error:any) =>
      {
        console.warn(error);
        this.listLoader = false
      }
    );
  }
  selectVideo(id:string)
  {
    window.location.href = `/watch?id=${id}`
  }
  

}

