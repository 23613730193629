import { Component } from '@angular/core';
import { GlobalStore } from './service/global.service';

import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    public global : GlobalStore,
    private ApiService: ApiService,
  ) {
  }
  'allowTop' : boolean = false;
  'whatsappData' : any = [];

  ngOnInit(): void {
    this.global.getUserInfo();

    window.addEventListener('scroll', () =>
    {
      let space : any = document.querySelector('.headerspace');
      if(space)
      {
        let data = space.getBoundingClientRect();
        this.allowTop = data.top < -240;
      }
    });

    this.ApiService.getCsList({}).subscribe(
      (res: any) => {
        if(res.data && res.data.length > 0)
        {
          this.whatsappData = JSON.parse(JSON.stringify(res.data));
        }
      },
      (error:any) =>
      {
        console.warn(error);
      }
    );
  }
  ngOnDestroy()
  {
    window.removeEventListener('scroll', () => {});
  }

  goTop()
  {
    window.scrollTo(0, 0);
  }
  goWhatsapp(index:number)
  {
    if(index === 0)
    {
      window.location.href = '/cs';
    }
    if(index === 1)
    {
      window.location.href = '/cs2';
    }
  }

  doButtonAction()
  {
    if(this.global.dialog.callback)
    {
      this.global.dialog.callback();
    }
  }
}
