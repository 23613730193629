import { Component, OnInit, Input } from '@angular/core';
import { GlobalStore } from 'src/app/service/global.service';
import { ActivatedRoute, Router } from '@angular/router';

import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'event-box',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss'],
})

export class EventBox implements OnInit {

  constructor(
    public global : GlobalStore,
    private route: ActivatedRoute,
    public router: Router,
    private ApiService: ApiService
  ) { }

  'listData' : any = [];

  ngOnInit(): void {

    
  }

  getEvent()
  {
    this.listData = [];
    let dataArr : any = [];
    this.ApiService.getVideoList({
      'tag'         : 'WPT',
      'filter[type]': 'normal'
    }).subscribe(
      (res: any) => {
        if(res.data && res.meta)
        {
          if(res.data && res.data.length > 0)
          {
            let arr = JSON.parse(JSON.stringify(res.data));
            arr.map((item:any) =>
            {
              let dataObj = JSON.parse(JSON.stringify(item));
              dataObj.desc = item.description ? item.description.replace(/<[^>]+>/g, '') : '';
              dataArr.push(dataObj);
            });
            this.listData = dataArr.sort().reverse();
            this.listData.length = 3;
          }
        }
      },
      (error:any) =>
      {
        console.warn(error);
      }
    );
  }

}