import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";

import { ApiService } from 'src/app/service/api.service';
import { GlobalStore } from 'src/app/service/global.service';

// import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './watch.page.html',
  styleUrls: ['./watch.page.scss']
})
export class WatchPage implements OnInit {
  
  constructor(
    private ApiService: ApiService,
    private route: ActivatedRoute,
    public router: Router,
    private sanitizer: DomSanitizer,
    public global: GlobalStore
    // public translate: TranslateService,
  ) {
    this.route.queryParams
      .subscribe((params:any) => 
      {
        if(params.id)
        {
          this.videoId = params.id;
          this.getVideoDetail();
          this.getVideoList();
        }
      }
    );
  }

  'videoId' : string = '';
  'videoData' : any = null;
  'otherData' : any = [];
  'loader'   : boolean = false;
  'style' : any = `
    <style>
      p {display:block; position:relative; width:100%; font-size:14px; line-height:22px; padding:0px; margin:0px 0px 10px 0px;}
      p:last-child {margin:0px;}
      ul {display:block; position:relative; margin:0px; padding:0px 0px 0px 28px;}
      ul li p{margin:0px;}
    </style>
  `;
  'iframeUrl' : SafeUrl = '';

  ngOnInit(): void {

  }

  getVideoDetail()
  {
    this.videoData = '';
    this.ApiService.getVideoDetail({'id': this.videoId}).subscribe(
      (res: any) => {
        if(res.data)
        {
          let dataObj = JSON.parse(JSON.stringify(res.data));
          dataObj.datetime = `${res.data.created_at.split('T')[0]} ${res.data.created_at.split('T')[1].split('.')[0]}`;
          dataObj.desc = this.sanitizer.bypassSecurityTrustHtml(`${this.style}${res.data.description}`);

          // this.videoData = {
          //   'url' : {
          //     'flv' : '',
          //     'hls' : '',
          //     'mp4' : dataObj.output_mp4_url.includes('drive.google.com') ? `${dataObj.output_mp4_url.split('/view')[0]}/preview` : dataObj.output_mp4_url
          //   },
          //   'id'      : dataObj.id,
          //   'img'     : dataObj.img_url,
          //   'type'    : dataObj.output_mp4_url.includes('drive.google.com') ? 'embed' : 'video',
          //   'data'    : dataObj
          // }
          this.videoData = {
            'url' : {
              'flv' : '',
              'hls' : 'http://live-hls.qztuauj.cn/live/24x7stream/playlist.m3u8',
              'mp4' : ''
            },
            'id'      : dataObj.id,
            'img'     : dataObj.img_url,
            'type'    : 'live',
            'data'    : dataObj
          }
          if(dataObj.output_mp4_url.includes('drive.google.com'))
          {
            this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${dataObj.output_mp4_url.split('/view')[0]}/preview`)
          }
        }
      },
      (error:any) =>
      {
        console.warn(error);
      }
    );
  }
  getVideoList()
  {
    this.otherData = [];
    let dataArr : any = [];
    this.ApiService.getVideoList({}).subscribe(
      (res: any) => {
        if(res.data && res.meta)
        {
          if(res.data && res.data.length > 0)
          {
            let arr = JSON.parse(JSON.stringify(res.data));
            arr.map((item:any) =>
            {
              let dataObj = JSON.parse(JSON.stringify(item));
              if(item.id !== this.videoId)
              {
                dataArr.push(dataObj);
              }
            });
            this.otherData = this.global.shuffleArray(dataArr);
            this.otherData.length = 2;
          }
        }
        else
        {
          this.loader = false;
        }
      },
      (error:any) =>
      {
        console.warn(error);
        this.loader = false;
      }
    );
  }

  goDownload()
  {
    window.open(this.global.downloadUrl);
  }
  

}

