import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GlobalStore } from '../../service/global.service';

import PhoneCode from 'src/assets/phone-code.json';
import CountryCode from 'src/assets/country-code.json';

@Component({
  selector: 'areacode',
  templateUrl: './areacode.component.html',
  styleUrls: ['./areacode.component.scss'],
})

export class Areacode implements OnInit {

  @Input() status : boolean = false;

  constructor(
    public global : GlobalStore
  ) { }

  'show' : boolean = false;
  'PhoneCode' : any = PhoneCode;
  'CountryCode' : any = CountryCode;
  'mobileCode' : any = [];
  'searchedCode': any = [];
  'searchText' : string = '';
  

  ngOnInit(): void {
    this.getCountryName();
  }
  
  getCountryName()
  {
    this.mobileCode = [];
    this.PhoneCode.map((item:any) =>
    {
      let dataObj = JSON.parse(JSON.stringify(item));
      dataObj.desc = '';
      let index = this.CountryCode.findIndex((x:any) => x.ISO2 === item.code)
      if(index > -1)
      {
        dataObj.desc = this.CountryCode[index].China;
      }

      dataObj.title = item.name;
      dataObj.value = item.dial_code.includes('+') ? item.dial_code.split('+')[1] : item.dial_code;
      
      this.mobileCode.push(dataObj);
    });

    this.searchedCode = JSON.parse(JSON.stringify(this.mobileCode))
  }

  @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();
  selectDialCode(data:any)
  {
    let dataObj = JSON.parse(JSON.stringify(data));
    this.onSelect.emit(dataObj);
    this.clearSearch();
  }
  search(event:any)
  {
    this.searchedCode = [];
    this.mobileCode.map((item:any) =>
    {
      let lowTitle = item.title.toLowerCase();
      let descTitle = item.desc.toLowerCase();
      let valueTitle = item.value.toLowerCase();
      if(lowTitle.includes(this.searchText.toLowerCase()) || descTitle.includes(this.searchText.toLowerCase()) || valueTitle.includes(this.searchText.toLowerCase()))
      {
        this.searchedCode.push(item);
      }
    })
  }
  clearSearch()
  {
    if(this.searchText)
    {
      this.searchText = '';
      this.search(null);
    }
  }

  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
  close()
  {
    this.onClose.emit();
  }
  

}