<div class="logon">
    <div class="logoncover">
        <div class="box">
            <div class="logo"><img src="../../../assets/img/wpt-logo.png" /></div>
            <div class="text">Find Your Password</div>
            <div class="tab">
                <div [class]="{'active' : tabIndex === 0}" (click)="selectTab(0)">Email<span></span></div>
                <div [class]="{'active' : tabIndex === 1}" (click)="selectTab(1)">Mobile<span></span></div>
            </div>
            <div class="form">
                <div class="inputbox" *ngIf="tabIndex === 0">
                    <i class="iconfont icon-user"></i>
                    <input [(ngModel)]="formData.email" type="text" name="user" placeholder="Email" autocomplete="off" />
                    <span [class]="{'disabled' : formData.email === '' || otpLoader, 'counting' : otpText !== 'Get OTP'}" (click)="getYzm()">{{ otpText }}</span>
                </div>
                <div class="inputbox" *ngIf="tabIndex === 0">
                    <i class="iconfont icon-otp"></i>
                    <input [(ngModel)]="formData.yzm" type="text" name="otp" placeholder="Email OTP" autocomplete="off" />
                </div>
                <div class="inputbox" *ngIf="tabIndex === 1">
                    <i class="iconfont icon-phone"></i>
                    <input [(ngModel)]="formData.mobile" type="text" name="mobile" placeholder="Phone Number" autocomplete="off" />
                    <span [class]="{'disabled' : formData.mobile === '' || otpLoader, 'counting' : otpText !== 'Get OTP'}" (click)="getYzm()">{{ otpText }}</span>
                </div>
                <div class="inputbox" *ngIf="tabIndex === 1">
                    <i class="iconfont icon-otp"></i>
                    <input [(ngModel)]="formData.yzm" type="text" name="otp" placeholder="Mobile OTP" autocomplete="off" />
                </div>
                <div class="inputbox">
                    <i class="iconfont icon-password"></i>
                    <input [(ngModel)]="formData.password" type="text" name="access" placeholder="New Password" autocomplete="off" />
                </div>
                <div class="inputbox">
                    <i class="iconfont icon-password"></i>
                    <input [(ngModel)]="formData.conpassword" type="text" name="access" placeholder="Confirm New Password" autocomplete="off" />
                </div>
            </div>
            <div class="button">
                <div>Submit</div>
            </div>
            <div class="login">
                <span>Found Your Password?</span>
                <div>Login Now</div>
            </div>
        </div>
    </div>
</div>