<wpt-header [loader]="global.loginLoader" [user]="global.userInfo"></wpt-header>
<router-outlet></router-outlet>

<div class="float-cs">
    <div class="whatsapp" *ngFor="let item of whatsappData; index as index;" (click)="goWhatsapp(index)">
        <i class="iconfont icon-whatsapp"></i>
        <span>{{ index+1 }}</span>
    </div>
    <div class="top" [class]="{'active' : allowTop}" (click)="goTop()"><i class="iconfont icon-arrow-up"></i></div>
</div>

<div class="dialog" *ngIf="global.dialog">
    <div class="overlay"></div>
    <div class="dialogcover">
        <div class="border" [class]="global.dialog.mode"></div>
        <div class="content" [class]="{'center' : !global.dialog.button && !global.dialog.action}">
            <div class="text">
                <span *ngFor="let text of global.dialog.list; index as index;">{{ text }}</span>
            </div>
            <div class="button">
                <div *ngIf="global.dialog.button" (click)="global.dialog = null">{{ global.dialog.button }}</div>
                <span *ngIf="global.dialog.action"(click)="doButtonAction()">{{ global.dialog.action }}</span>
            </div>
        </div>
    </div>
</div>