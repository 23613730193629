<div class="banner">
    <swiper id="bannerSwiper" [config]="swiperConfig" (init)="startSwiper($event)" (activeIndexChange)="slideChange($event)">
        <ng-template swiperSlide *ngFor="let item of bannerData; index as index;">
            <div class="box" [style]="{'background' : item.bg_color}">
                <div class="boxcover" [class]="{'clickable' : !item.description && item.redirect_url}" (click)="goBannerLink(item)">
                    <img class="pc" [src]="item.img_url" />
                    <img class="h5" [src]="item.h5_img_url" />
                </div>
            </div>
        </ng-template>
    </swiper>
</div>
<div class="pagination">
    <div class="pagcover">
        <div class="pagbox" [class]="{'active' : selectedIndex === index}" *ngFor="let item of bannerData; index as index;" [style]="{'background-image' : 'url(' + item.img_url + ')'}" (click)="selectPag(index)">
            <img src="../../../assets/img/videocover.png" />
        </div>
    </div>
</div>
<!-- <div class="detailbox">
    <div class="detailcover">
        <div class="box">
            <div class="icon"><img src="../../../assets/img/detail1.png" /></div>
            <div class="title">20 Years at the Top</div>
            <div class="desc">We combined 20 years of World Poker Tour legacy with the most professional team to create the premier destination for online poker worldwide.</div>
        </div>
        <div class="box">
            <div class="icon"><img src="../../../assets/img/detail2.png" /></div>
            <div class="title">Playing Field For All Skill Levels</div>
            <div class="desc">The innovative WPT Global software allows only two pro players per cash game table, meaning players of all skill levels have the best place to learn, play, improve and win.</div>
        </div>
        <div class="box">
            <div class="icon"><img src="../../../assets/img/detail3.png" /></div>
            <div class="title">Zero Tolerance for Bots & Cheats</div>
            <div class="desc">Data-driven AI detection along with game theory eliminates bots and collusion. Safe and fair game environment for all our players guaranteed.</div>
        </div>
    </div>
</div> -->
<div class="promobox">
    <div class="promocover">
        <div class="title">Let's Join Our</div>
        <div class="desc">{{ month }} Special Event</div>
        <div class="note">Join us now and don't miss out any latest news</div>
        <div class="list">
            <div class="box" *ngFor="let item of promoData; index as index;" (click)="goDetail(item.id)">
                <div class="img" [style]="{'background' : 'url(' + item.img_url + ')'}"><img src="../../../assets/img/videocover.png" /></div>
                <div class="text">{{ item.title }}</div>
            </div>
        </div>
        <div class="button" *ngIf="isMorePromo">
            <span (click)="goPromotion()">View All Events</span>
        </div>
    </div>
</div>
<div class="tab-menu">
    <div class="tabcover">
        <div class="title">Check Out Our</div>
        <div class="desc">Live Streams & Videos Too</div>
        <div class="tab">
            <div class="list" *ngIf="dataList && !listLoader">
                <div class="box" *ngFor="let item of dataList; index as index;" (click)="selectVideo(item.id)">
                    <div class="image" [style]="{'background-image' : 'url(' + item.img_url + ')'}">
                        <img src="../../../assets/img/videocover.png">
                        <span><i class="iconfont icon-play"></i></span>
                    </div>
                    <div class="content">
                        <div class="title">
                            <div>{{ item.title }}</div>
                            <span>{{ item.desc }}</span>
                        </div>
                        <div class="detail">
                            <div>{{ item.createddate }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="listloader" *ngIf="listLoader">
                <div class="loadbox"><div><img src="../../../assets/img/videocover.png" /></div><span></span></div>
                <div class="loadbox"><div><img src="../../../assets/img/videocover.png" /></div><span></span></div>
                <div class="loadbox"><div><img src="../../../assets/img/videocover.png" /></div><span></span></div>
                <div class="loadbox"><div><img src="../../../assets/img/videocover.png" /></div><span></span></div>
                <div class="loadbox"><div><img src="../../../assets/img/videocover.png" /></div><span></span></div>
                <div class="loadbox"><div><img src="../../../assets/img/videocover.png" /></div><span></span></div>
                <div class="loadbox"><div><img src="../../../assets/img/videocover.png" /></div><span></span></div>
                <div class="loadbox"><div><img src="../../../assets/img/videocover.png" /></div><span></span></div>
            </div>
            <div class="empty" *ngIf="!listLoader && dataList.length === 0">
                No Data
            </div>
        </div>
    </div>
</div>

<!-- <tab-menu></tab-menu> -->

