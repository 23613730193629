import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from "@angular/platform-browser";

import { ApiService } from 'src/app/service/api.service';
import { GlobalStore } from 'src/app/service/global.service';

// import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './quiz.page.html',
  styleUrls: ['./quiz.page.scss']
})
export class QuizPage implements OnInit {
  
  constructor(
    private ApiService: ApiService,
    private route: ActivatedRoute,
    public router: Router,
    private sanitizer: DomSanitizer,
    public global: GlobalStore
    // public translate: TranslateService,
  ) {
    this.route.queryParams
      .subscribe((params:any) => 
      {
        if(params.id)
        {
          this.quizId = params.id;
          this.global.dialog = {
            'mode'  : '',
            'button': '',
            'list'  : [`Loading Page, Hold On...`]
          };
          this.global.getUserInfo(() =>
          {
            this.getQuizDetail();
          }, () =>
          {
            window.location.href = '/login'
          });
        }
        else
        {
          this.global.dialog = {
            'mode'  : '',
            'button': '',
            'action': 'OK, Got It',
            'list'  : [`Sorry, challenge not found.`],
            'callback' : (): void =>
            {
              window.location.href = '/challenge'
            }
          };
        }
      }
    );
  }

  'quizId' : string = '';
  'quizDetail': any = null;
  'style' : any = `
    <style>
      p {display:block; position:relative; width:100%; font-size:16px; line-height:26px; padding:0px; margin:0px 0px 14px 0px;}
      p:last-child {margin:0px;}
      ul {display:block; position:relative; margin:0px 0px 14px 0px; padding:0px 0px 0px 28px;}
      ul:last-child {margin:0px;}
      ul li p {margin:0px;}
    </style>
  `;
  'mode' : number = 0;
  'step' : number = 0;
  'selectedQuestion' : any = null;
  'loader' : boolean = false;
  'last' : boolean = false;
  'float' : boolean = false;
  'currentData' : any = null;

  ngOnInit(): void {

  }

  getQuizDetail()
  {
    this.quizDetail = null;
    this.ApiService.getChallengeDetail({
      'id' : this.quizId
    }).subscribe(
      (res: any) => {
        if(res.data)
        {
          let data = JSON.parse(JSON.stringify(res.data));
          data.contents.map((item:any) =>
          {
            item.text = '';
            if(item.type === 'paragraph')
            {
              item.text = this.sanitizer.bypassSecurityTrustHtml(`${this.style}${item.content}`);
            }
          });

          let xp : any = data.qnas.map((item:any) =>
          {
            return item.xp_gain;
          })
          let sumxp = xp.reduce((a:any, b:any) => a + b, 0);
          data.sum_xp = sumxp;

          this.quizDetail = data;
          this.getCurrentQuizState();
        }
      },
      (error:any) =>
      {
        console.warn(error);
        this.global.dialog = {
          'mode'  : '',
          'button': '',
          'action': 'Go Back',
          'list'  : [`Sorry, challenge not found.`],
          'callback' : (): void =>
          {
            window.location.href = '/challenge'
          }
        };
      }
    );
  }
  getCurrentQuizState()
  {
    this.ApiService.getActiveChallenge().subscribe(
      (res: any) => {
        if(res.data)
        {
          this.global.dialog = null;
          this.currentData = JSON.parse(JSON.stringify(res.data));
          this.currentData.percentage = (res.data.completed_qnas / res.data.total_qnas) * 100;

          if(this.currentData.completed_qnas > 0)
          {
            this.step = this.currentData.completed_qnas;
            this.mode = 1;

            this.selectedQuestion = null;
            let optiondata : any = [];
            this.quizDetail.qnas[this.step].options.map((item:any) =>
            {
              optiondata.push({
                'status' : false,
                'value'  : item
              });
            });
            this.selectedQuestion = JSON.parse(JSON.stringify(this.quizDetail.qnas[this.step]));
            this.selectedQuestion.options = optiondata;

            window.scrollTo(0, 0);

            if(this.currentData.completed_qnas === this.currentData.total_qnas)
            {
              this.last = true;
            }
          }
        }
      },
      (error:any) =>
      {
        console.warn(error);
        this.global.dialog = {
          'mode'  : '',
          'button': '',
          'action': 'Go Back',
          'list'  : [`Sorry, challenge not found.`],
          'callback' : (): void =>
          {
            window.location.href = '/challenge'
          }
        };
      }
    );
  }

  start()
  {
    this.mode = 1;
    this.step = 0;
    this.selectedQuestion = null;
    let optiondata : any = [];
    this.quizDetail.qnas[this.step].options.map((item:any) =>
    {
      optiondata.push({
        'status' : false,
        'value'  : item
      });
    });
    this.selectedQuestion = JSON.parse(JSON.stringify(this.quizDetail.qnas[this.step]));
    this.selectedQuestion.options = optiondata;

    window.scrollTo(0, 0);

    if(this.step === this.quizDetail.qnas.length - 1)
    {
      this.last = true;
    }
  }
  read()
  {
    this.mode = 0;
  }
  continue()
  {
    this.mode = 1;
  }
  submit()
  {
    if(this.last)
    {
      let index = this.selectedQuestion.options.findIndex((x:any) => x.status);
      if(index === -1)
      {
        this.global.dialog = {
          'mode'  : 'error',
          'button': 'Confirm',
          'list'  : [`Please answer the question before you submit.`]
        };
      }
      else
      {
        this.ApiService.submitAnswer({
          'id' : this.selectedQuestion.id,
          'answer' : index + 1
        }).subscribe(
          (res: any) => {
            if(res.data)
            {
              this.global.dialog = {
                'mode'  : 'success',
                'button': '',
                'action': 'Confirm',
                'list'  : [`${res.data.message}`],
                'callback' : () : void => 
                {
                  this.global.dialog = null;
                  this.ApiService.completeChallenge({
                    'id' : this.quizId
                  }).subscribe(
                    (res: any) => {
                      if(res.data)
                      {
                        this.global.dialog = {
                          'mode'  : 'success',
                          'button': '',
                          'action': 'Confirm',
                          'list'  : [`XP Gained : ${res.data.total_xp_gained}`, `Total Correct : ${res.data.total_correct}`, `Total Wrong : ${res.data.total_wrong}`],
                          'callback' : () : void => 
                          {
                            window.location.href = '/challenge';
                          }
                        };
                      }
                    },
                    (error:any) =>
                    {
                      console.warn(error);
                      this.global.dialog = {
                        'mode'  : 'error',
                        'button': 'Confirm',
                        'list'  : [`${error.error.message}`]
                      };
                    }
                  );
                }
              };
              
            }
          },
          (error:any) =>
          {
            console.warn(error);
            this.global.dialog = {
              'mode'  : 'error',
              'button': 'Confirm',
              'list'  : [`${error.error.message}`]
            };
          }
        );
      }
    }
    else
    {
      let index = this.selectedQuestion.options.findIndex((x:any) => x.status);
      if(index === -1)
      {
        this.global.dialog = {
          'mode'  : 'error',
          'button': 'Confirm',
          'list'  : [`Please answer the question before you submit.`]
        };
      }
      else
      {
        this.ApiService.submitAnswer({
          'id' : this.selectedQuestion.id,
          'answer' : index + 1
        }).subscribe(
          (res: any) => {
            if(res.data)
            {
              this.global.dialog = {
                'mode'  : 'success',
                'button': '',
                'action': 'Confirm',
                'list'  : [`${res.data.message}`],
                'callback' : () : void => 
                {
                  this.global.dialog = null;
                  this.next();
                }
              };
            }
          },
          (error:any) =>
          {
            console.warn(error);
            this.global.dialog = {
              'mode'  : 'error',
              'button': 'Confirm',
              'list'  : [`${error.error.message}`]
            };
          }
        );
      }
    }
    
  }
  next()
  {
    this.step += 1;
    this.selectedQuestion = null;
    let optiondata : any = [];
    this.quizDetail.qnas[this.step].options.map((item:any) =>
    {
      optiondata.push({
        'status' : false,
        'value'  : item
      });
    });
    this.selectedQuestion = JSON.parse(JSON.stringify(this.quizDetail.qnas[this.step]));
    this.selectedQuestion.options = optiondata;

    if(this.step === this.quizDetail.qnas.length - 1)
    {
      this.last = true;
    }
  }
  selectOption(i:number)
  {
    this.selectedQuestion.options.map((item:any, index:number) =>
    {
      if(index === i)
      {
        item.status = !item.status;
        if(item.status)
        {
          this.currentData.percentage = `${((this.currentData.completed_qnas + 1) / this.currentData.total_qnas) * 100}`;
        }
        else
        {
          this.currentData.percentage = `${(this.currentData.completed_qnas / this.currentData.total_qnas) * 100}`;
        }
      }
      else
      {
        item.status = false;
      }
    })
  }
  

}

