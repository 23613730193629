import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from "@angular/platform-browser";

import { ApiService } from 'src/app/service/api.service';

// import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './events.page.html',
  styleUrls: ['./events.page.scss']
})
export class EventPage implements OnInit {
  
  constructor(
    private ApiService: ApiService,
    private route: ActivatedRoute,
    public router: Router,
    private sanitizer: DomSanitizer
    // public translate: TranslateService,
  ) {
    
  }

  'promoData' : any = [];


  ngOnInit(): void {

    this.getPromotion();
  }

  getPromotion()
  {
    this.promoData = [];
    let dataArr : any = [];
    this.ApiService.getHotEventList({}).subscribe(
      (res: any) => {
        if(res.data && res.meta)
        {
          if(res.data && res.data.length > 0)
          {
            let arr = JSON.parse(JSON.stringify(res.data));
            arr.map((item:any) =>
            {
              let dataObj = JSON.parse(JSON.stringify(item));
              dataArr.push(dataObj);
            });
            
            this.promoData = dataArr;
          }
        }
      },
      (error:any) =>
      {
        console.warn(error);
      }
    );
  }

  goDetail(id:string)
  {
    window.location.href = `/promotion?id=${id}`
  }
  

}

