import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomePage } from './pages/home/home.page';
import { CsPage } from './pages/cs/cs.page';
import { Cs2Page } from './pages/cs2/cs2.page';
import { ContactPage } from './pages/contact/contact.page';
import { ChallengePage } from './pages/challenge/challenge.page';
import { LoginPage } from './pages/login/login.page';
import { RegisterPage } from './pages/register/register.page';
import { ForgetPage } from './pages/forget/forget.page';
import { QuizPage } from './pages/quiz/quiz.page';
import { VideosPage } from './pages/videos/videos.page';
import { WatchPage } from './pages/watch/watch.page';
import { EventPage } from './pages/events/events.page';
import { PromotionPage } from './pages/promotion/promotion.page';

const routes: Routes = [
  {
    path: '',
    component: HomePage,
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'login',
    component: LoginPage,
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'register',
    component: RegisterPage,
    loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'forget',
    component: ForgetPage,
    loadChildren: () => import('./pages/forget/forget.module').then(m => m.ForgetPageModule)
  },
  {
    path: 'videos',
    component: VideosPage,
    loadChildren: () => import('./pages/videos/videos.module').then(m => m.VideosPageModule)
  },
  {
    path: 'watch',
    component: WatchPage,
    loadChildren: () => import('./pages/watch/watch.module').then(m => m.WatchPageModule)
  },
  {
    path: 'events',
    component: EventPage,
    loadChildren: () => import('./pages/events/events.module').then(m => m.EventPageModule)
  },
  {
    path: 'promotion',
    component: PromotionPage,
    loadChildren: () => import('./pages/promotion/promotion.module').then(m => m.PromotionPageModule)
  },
  {
    path: 'challenge',
    component: ChallengePage,
    loadChildren: () => import('./pages/challenge/challenge.module').then(m => m.ChallengePageModule)
  },
  {
    path: 'quiz',
    component: QuizPage,
    loadChildren: () => import('./pages/quiz/quiz.module').then(m => m.QuizPageModule)
  },
  {
    path: 'cs',
    component: CsPage,
    loadChildren: () => import('./pages/cs/cs.module').then(m => m.CsPageModule)
  },
  {
    path: 'cs2',
    component: Cs2Page,
    loadChildren: () => import('./pages/cs2/cs2.module').then(m => m.Cs2PageModule)
  },
  {
    path: 'contact',
    component: ContactPage,
    loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactPageModule)
  },
  { path: '', redirectTo: '', pathMatch: 'full' },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
