import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ApiService } from 'src/app/service/api.service';

// import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './cs.page.html',
  styleUrls: ['./cs.page.scss']
})
export class CsPage implements OnInit {
  
  constructor(
    private ApiService: ApiService,
    private route: ActivatedRoute,
    public router: Router
    // public translate: TranslateService,
  ) {
  }

  ngOnInit(): void {

    let body : any = document.querySelector('body');
    if(body)
    {
      body.style.height = '100vh';
      body.style.overflow = 'hidden';
    }

    this.ApiService.getCsList({
      'filter[name]' : 'cs1'
    }).subscribe(
      (res: any) => {
        if(res.data && res.data.length > 0)
        {
          window.location.href = `http://wa.me/${res.data[0].phone_number}`;
        }
      },
      (error:any) =>
      {
        console.warn(error);
      }
    );
  }
  

}

