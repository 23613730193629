<div class="fang-dial" *ngIf="status">
    <div class="shadow" (click)="close()"></div>
    <div class="selectioncover">
        <div class="search">
            <input type="text" [(ngModel)]="searchText" placeholder="Search" (keyup)="search($event)" />
            <span (click)="clearSearch()"><i class="iconfont" [class]="searchText ? 'pkf-close' : 'pkf-search'"></i></span>
        </div>
        <div class="list">
            <div class="box" [class]="{'show' : item.searched}" *ngFor="let item of searchedCode; index as iindex;" (click)="selectDialCode(item)">
                <div class="left-long">
                    {{ item.title }}
                </div>
                <div class="right-short">
                    {{ item.dial_code }}
                </div>
            </div>
        </div>
    </div>
</div>