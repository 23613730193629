<div class="quiz">
    <div class="head">
        <div class="overlay"></div>
        <div class="headcover" *ngIf="quizDetail">
            <div class="title">{{ quizDetail.title }}</div>
            <div class="detail">
                <div class="detailbox">
                    <div>
                        <img src="../../../assets/img/icon/level.png" />
                    </div>
                    <span><span>Level {{ quizDetail.level }}</span></span>
                </div>
                <div class="detailbox">
                    <div>
                        <img src="../../../assets/img/icon/medal.png" />
                    </div>
                    <span><span>{{ quizDetail.sum_xp }} XP</span></span>
                </div>
                <div class="detailbox">
                    <div>
                        <img src="../../../assets/img/icon/question.png" />
                    </div>
                    <span><span>{{ quizDetail.qnas.length }} Questions</span></span>
                </div>
            </div>
        </div>
    </div>
    <div class="body">
        <div class="bodycover" *ngIf="quizDetail">
            <div class="content" *ngIf="mode === 0">
                <div class="row" *ngFor="let item of quizDetail.contents; index as index;">
                    <div class="text" *ngIf="item.type === 'paragraph'" [innerHTML]="item.text"></div>
                    <div class="image" *ngIf="item.type === 'image'">
                        <img [src]="item.content" />
                    </div>
                    <div class="video" *ngIf="item.type === 'video'">
                        <video controls [src]="item.content"></video>
                    </div>
                </div>

                <div class="start">
                    <div *ngIf="step === 0" (click)="start()">Start Quiz Test</div>
                    <div *ngIf="step > 0" (click)="continue()">Back to Quiz</div>
                </div>
            </div>
            <div class="question" *ngIf="mode === 1">
                <div class="row">
                    <div class="ques"><span>{{ step + 1 }}.</span>{{ selectedQuestion.question }}</div>
                    <div class="option">
                        <div class="box" [class]="{'active' : option.status}" *ngFor="let option of selectedQuestion.options; index as oindex;" (click)="selectOption(oindex)">
                            <span></span>
                            {{ option.value }}
                        </div>
                    </div>
                </div>

                <div class="next">
                    <span (click)="read()">Back to Article</span>
                    <div (click)="submit()">Submit Question {{ step + 1 }}</div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="progress" *ngIf="currentData">
    <span [style]="{'width' : currentData.percentage + '%'}"></span>
</div>