<div class="contentheader" style="background-image: url(../../../assets/img/banner/2.jpg)">
    <div class="backdrop"></div>
    <div class="headercover">
        <div class="text">
            <div class="title">Events & News</div>
            <div class="desc">Join us now and don't miss out any latest promotions and news</div>
        </div>
    </div>
</div>

<div class="event">
    <div class="eventcover">
        <div class="box" *ngFor="let item of promoData; index as index;" (click)="goDetail(item.id)">
            <div class="img" [style]="{'background-image' : 'url(' + item.img_url + ')'}">
                <img src="../../../assets/img/videocover.png" />
            </div>
            <div class="text">{{ item.title }}</div>
        </div>
    </div>
</div>