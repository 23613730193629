<div class="contentheader" style="background-image: url(../../../assets/img/banner/1.jpg)">
    <div class="backdrop"></div>
    <div class="headercover">
        <div class="watchtitle" *ngIf="videoData">{{ videoData.data.title }}</div>
        <div class="watchdate" *ngIf="videoData">{{ videoData.data.datetime }}</div>
    </div>
</div>


<div class="watch">
    <div class="watchcover">
        <div class="contain">
            <div class="player" *ngIf="videoData">
                <div class="playercover">
                    <video-player *ngIf="videoData.type !== 'embed'" [data]="videoData"></video-player>
                    <div class="iframe" *ngIf="videoData.type === 'embed'">
                        <img src="../../../assets/img/videocover.png" />
                        <iframe [src]="iframeUrl" allow="autoplay"></iframe>
                    </div>
                </div>
                <div class="description">
                    <div [innerHTML]="videoData.data.desc"></div>
                </div>
            </div>
            <div class="sidebar">
                <div class="download">
                    <div class="icon">
                        <img src="../../../assets/img/appicon.svg" />
                    </div>
                    <div class="title">WPT Global</div>
                    <div class="desc">Grab your 100% First Deposit Bonus up to $1,200 at the Online Home of the World Poker Tour</div>
                    <div class="button">
                        <span (click)="goDownload()">Download WPT Global</span>
                    </div>
                </div>
                <div class="list">
                    <div class="box" *ngFor="let item of otherData; index as index;" (click)="global.navigate('watch?id=' + item.id)">
                        <div class="img" [style]="{'background-image' : 'url(' + item.img_url + ')'}"><img src="../../../assets/img/videocover.png" /></div>
                        <div class="content">
                            <div class="title">{{ item.title }}</div>
                            <div class="desc">{{ item.desc }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="clear-row"></div>
        </div>
    </div>
</div>