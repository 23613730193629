import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as qs from 'qs';

let nowDate = new Date();
let getNowDate = nowDate.getFullYear() + '-' + ((nowDate.getMonth() + 1) >= 10 ? (nowDate.getMonth() + 1) : '0' + (nowDate.getMonth() + 1)) + '-' + (nowDate.getDate() >= 10 ? nowDate.getDate() : '0' + nowDate.getDate()) + ' ' + (nowDate.getHours() >= 10 ? nowDate.getHours() : '0' + nowDate.getHours()) + ':' + (nowDate.getMinutes() >= 10 ? nowDate.getMinutes() : '0' + nowDate.getMinutes()) + ':' + (nowDate.getSeconds() >= 10 ? nowDate.getSeconds() : '0' + nowDate.getSeconds());

const httpOptions = () => ({
  headers: new HttpHeaders({ 
    'Accept'      : '*/*',
    'Content-Type': 'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin' : '*',
    'Authorization': `Bearer ${localStorage.getItem('token') || ''}`,
    // 'languagecode': localStorage.getItem('languageCode') || '512',
    // 'userId'      : useGuser ? JSON.parse(localStorage.getItem('gUserIdToken') as string).userId : localStorage.getItem('userId') || '',
    // 'sid'         : sessionStorage.getItem('sId') || '',
    'timeZoneCode': `${Math.round(new Date().getTimezoneOffset() / 60)}`,
    'dateTime'    : getNowDate,
  })
});

const formDataHttpOption = () => ({
  headers: new HttpHeaders({ 
    'Accept'      : '*/*',
    'Access-Control-Allow-Origin' : '*',
    'Authorization': `Bearer ${localStorage.getItem('token') || ''}`,
    // 'languagecode': localStorage.getItem('languageCode') || '512',
    // 'userId'      : useGuser ? JSON.parse(localStorage.getItem('gUserIdToken') as string).userId : localStorage.getItem('userId') || '',
    // 'sid'         : sessionStorage.getItem('sId') || '',
    'timeZoneCode': `${Math.round(new Date().getTimezoneOffset() / 60)}`,
    'dateTime'    : getNowDate,
  })
});


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  'initParam': any = {}
  'domain' : string = '';

  constructor(
    private http: HttpClient
  ) {
    let location = window.location.href;
    if(location.indexOf('wptglobalmy.') !== -1)
    {
      this.domain = 'https://hub.wptglobalmy.com/front-api';
    }
    else
    {
      this.domain = 'https://wpt-api.pkfang.tech/front-api';
    }
  }

  // 注册
  registerUser(param:any)
  {
    let p = {
      ...param,
      ...this.initParam
    }
    return this.http.post(this.domain + '/auth/user', qs.stringify(p), httpOptions());
  }
  // 登录
  loginUser(param:any)
  {
    let p = {
      ...param,
      ...this.initParam
    }
    return this.http.post(this.domain + `/auth/token`, qs.stringify(p), httpOptions())
  }
  // 刷新用户 token
  refreshUserToken()
  {
    return this.http.get(this.domain + `/auth/token`, httpOptions())
  }
  // 获取用户数据
  getUserInfo()
  {
    return this.http.get(this.domain + `/auth/user`, httpOptions())
  }
  // 更新用户数据
  updateUserInfo(param:any)
  {
    let p = {
      ...param,
      ...this.initParam
    }
    return this.http.patch(this.domain + `/auth/user`, qs.stringify(p), httpOptions())
  }
  // 上传用户头像图片
  uploadProfileImage(file:any)
  {
    let formData : any = new FormData();
    formData.append('profile_img', file);
    return this.http.post(this.domain + `/auth/user/update-profile-image`, formData, formDataHttpOption())
  }
  // 上传用户背景图片
  uploadCoverImage(file:any)
  {
    let formData : any = new FormData();
    formData.append('cover_img', file);
    return this.http.post(this.domain + `/auth/user/update-cover-image`, formData, formDataHttpOption())
  }
  // 上传特大头像图片
  uploadAvatarImage(file:any)
  {
    let formData : any = new FormData();
    formData.append('avatar_img', file);
    return this.http.post(this.domain + `/auth/user/update-avatar-image`, formData, formDataHttpOption())
  }
  // 获取手机验证码，注册
  getRegisterOTP(param:any)
  {
    return this.http.post(this.domain + `/request-otp/registration`, qs.stringify(param), httpOptions())
  }
  // 获取手机验证码，绑定
  getPhoneVerifyOTP(param:any)
  {
    return this.http.post(this.domain + `/request-otp/phone-number-validation`, qs.stringify(param), httpOptions())
  }
  // 获取邮箱证码，绑定
  getEmailVerifyOTP(param:any)
  {
    return this.http.post(this.domain + `/request-otp/email-validation`, qs.stringify(param), httpOptions())
  }
  // 验证手机号
  verifyPhone(param:any)
  {
    return this.http.post(this.domain + `/auth/user/phone-number-validation`, qs.stringify(param), httpOptions())
  }
  // 验证邮箱
  verifyEmail(param:any)
  {
    return this.http.post(this.domain + `/auth/user/email-validation`, qs.stringify(param), httpOptions())
  }
  // 获取验证码，忘记密码
  getOTPResetPassword(param:any)
  {
    return this.http.post(this.domain + `/request-otp/reset-password`, qs.stringify(param), httpOptions())
  }
  // 修改忘记密码
  resetForgetPassword(param:any)
  {
    return this.http.post(this.domain + `/auth/forgot-password`, qs.stringify(param), httpOptions())
  }
  // 获取验证码，登录
  otpLogin(param:any)
  {
    return this.http.post(this.domain + `/auth/token/otp`, qs.stringify(param), httpOptions())
  }
  // 修改忘记密码
  getOTPLogin(param:any)
  {
    return this.http.post(this.domain + `/request-otp/login`, qs.stringify(param), httpOptions())
  }





  // 获取排行列表
  getRankList(param:any)
  {
    let queryString = Object.keys(param).map((key:any) => {
      return key + '=' + param[key]
    }).join('&');
    return this.http.get(this.domain + `/ranks` + (queryString ? `?${queryString}` : ''), httpOptions())
  }







  // 获取挑战列表
  getChallengeList(param:any)
  {
    let queryString = Object.keys(param).map((key:any) => {
      return key + '=' + param[key]
    }).join('&');
    return this.http.get(this.domain + `/challenges` + (queryString ? `?${queryString}` : ''), httpOptions())
  }
  // 获取挑战信息
  getChallengeDetail(param:any)
  {
    return this.http.get(this.domain + `/challenges/${param.id}`, httpOptions())
  }
  // 获取指定主播，直播间数据
  getActiveChallenge()
  {
    return this.http.get(this.domain + `/challenges/active-challenge`, httpOptions())
  }
  // 提交问题
  submitAnswer(param:any)
  {
    return this.http.post(this.domain + `/challenges/${param.id}/submit-answer?answer=${param.answer}`, qs.stringify(param), httpOptions())
  }
  // 结束挑战问题
  completeChallenge(param:any)
  {
    return this.http.post(this.domain + `/challenges/${param.id}/submit-challenge`, qs.stringify(param), httpOptions())
  }







  // 提交反馈
  sendEnquiry(param:any)
  {
    return this.http.post(this.domain + `/inquiries`, qs.stringify(param), httpOptions())
  }




  // 获取横幅列表
  getBannerList(param:any)
  {
    let queryString = Object.keys(param).map((key:any) => {
      return key + '=' + param[key]
    }).join('&');
    return this.http.get(this.domain + `/banners` + (queryString ? `?${queryString}` : ''), httpOptions())
  }





  // 获取直播列表
  getStreamList(param:any)
  {
    let queryString = Object.keys(param).map((key:any) => {
      return key + '=' + param[key]
    }).join('&');
    return this.http.get(this.domain + `/live-streams` + (queryString ? `?${queryString}` : ''), httpOptions())
  }
  // 获取指定主播，直播间数据
  getStreamDetail(param:any)
  {
    return this.http.get(this.domain + `/live-streams/${param.id}`, httpOptions())
  }
  // 获取指定主播，直播间数据
  updateStreamDetail(param:any)
  {
    return this.http.patch(this.domain + `/live-streams/${param.id}`, qs.stringify(param.data), httpOptions())
  }




  // 获取热门活动列表
  getHotEventList(param:any)
  {
    let queryString = Object.keys(param).map((key:any) => {
      return key + '=' + param[key]
    }).join('&');
    return this.http.get(this.domain + `/hot-events` + (queryString ? `?${queryString}` : ''), httpOptions())
  }
  // 获取热门活动信息
  getHotEventDetail(param:any)
  {
    return this.http.get(this.domain + `/hot-events/${param.id}`, httpOptions())
  }






  // 获取客服链接
  getCsList(param:any)
  {
    let queryString = Object.keys(param).map((key:any) => {
      return key + '=' + param[key]
    }).join('&');
    return this.http.get(this.domain + `/whatsapp-settings` + (queryString ? `?${queryString}` : ''), httpOptions())
  }





  // 获取视频列表
  getVideoList(param:any)
  {
    let queryString = Object.keys(param).map((key:any) => {
      return key + '=' + param[key]
    }).join('&');
    return this.http.get(this.domain + `/videos` + (queryString ? `?${queryString}` : ''), httpOptions())
  }
  // 获取视频信息
  getVideoDetail(param:any)
  {
    return this.http.get(this.domain + `/videos/${param.id}`, httpOptions())
  }

}

