import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from "@angular/platform-browser";

import { ApiService } from 'src/app/service/api.service';
import { GlobalStore } from 'src/app/service/global.service';

// import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './videos.page.html',
  styleUrls: ['./videos.page.scss']
})
export class VideosPage implements OnInit {
  
  constructor(
    private ApiService: ApiService,
    private route: ActivatedRoute,
    public router: Router,
    private sanitizer: DomSanitizer,
    public global: GlobalStore
    // public translate: TranslateService,
  ) {
  }

  'mainData' : any = null;
  'subData' : any = [];
  'otherData' : any = [];

  'videoData' : any = [];
  'pagiData' : any = [];
  'pageData' : any = null;
  'loader'   : boolean = false;

  ngOnInit(): void {

    this.getVideoList();
  }

  getVideoList()
  {
    this.videoData = [];
    let dataArr : any = [];
    this.ApiService.getVideoList({
      'per_page': 9,
      'page'    : this.pageData ? this.pageData.current_page: 1
    }).subscribe(
      (res: any) => {
        if(res.data && res.meta)
        {
          if(res.data && res.data.length > 0)
          {
            let arr = JSON.parse(JSON.stringify(res.data));
            arr.map((item:any) =>
            {
              let dataObj = JSON.parse(JSON.stringify(item));
              dataObj.desc = item.description ? item.description.replace(/<[^>]+>/g, '') : '';
              dataObj.createddate = item.created_at.split('T')[0];
              dataArr.push(dataObj);
            });
            this.videoData = dataArr;

            this.mainData = this.videoData[0];
            let sub : any = [];
            this.videoData.map((item:any, index:number) =>
            {
              if(index > 0 && index <= 3)
              {
                sub.push(item);
              }
            });
            this.subData = sub;
            let other : any = [];
            this.videoData.map((item:any, index:number) =>
            {
              if(index > 3)
              {
                other.push(item);
              }
            });
            this.otherData = other;

            this.pageData = JSON.parse(JSON.stringify(res.meta));
            this.loader = false;
          }
        }
        else
        {
          this.loader = false;
        }
      },
      (error:any) =>
      {
        console.warn(error);
        this.loader = false;
      }
    );
  }
  

}

