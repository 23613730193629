import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ApiService } from 'src/app/service/api.service';

// import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './contact.page.html',
  styleUrls: ['./contact.page.scss']
})
export class ContactPage implements OnInit {
  
  constructor(
    private ApiService: ApiService,
    private route: ActivatedRoute,
    public router: Router
    // public translate: TranslateService,
  ) {
    this.route.queryParams
      .subscribe((params:any) => 
      {
        if(params.name)
        {
          this.ApiService.getCsList({
            'filter[name]' : params.name
          }).subscribe(
            (res: any) => {
              if(res.data && res.data.length > 0)
              {
                window.location.href = `http://wa.me/${res.data[0].phone_number}`;
              }
            },
            (error:any) =>
            {
              console.warn(error);
            }
          );
        }
        else
        {
          this.ApiService.getCsList({
            'filter[name]' : 'cs1'
          }).subscribe(
            (res: any) => {
              if(res.data && res.data.length > 0)
              {
                window.location.href = `http://wa.me/${res.data[0].phone_number}`;
              }
            },
            (error:any) =>
            {
              console.warn(error);
            }
          );
        }
      }
    );
  }

  ngOnInit(): void {

    let body : any = document.querySelector('body');
    if(body)
    {
      body.style.height = '100vh';
      body.style.overflow = 'hidden';
    }
  }
  

}

