<div class="challenge">
    <div class="head">
        <div class="headcover" *ngIf="global.userInfo">
            <div class="profile">
                <div class="radial">
                    <div class="progressbar" [style]="{'background' : currentData ? ('conic-gradient(#5da3ff ' + currentData.percentage + '%, rgba(0,0,0,0) 0)') : ''}"></div>
                    <div class="image" [style]="{'background-image' : 'url(' + global.userInfo.profile_img_url + ')'}"></div>
                    <div class="mark">
                        <div class="dot" [style]="{'transform' : currentData ? ('rotate(' + ((360*currentData.percentage) / 100) + 'deg)') : '', '-webkit-transform' : currentData ? ('rotate(' + ((360*currentData.percentage) / 100) + 'deg)') : ''}"><span></span></div>
                    </div>
                </div>
                <div class="rank"><span>{{ global.userInfo.rank.current_xp }} XP</span></div>
            </div>
            <div class="name">{{ global.userInfo.name }}</div>
            <div class="detail">
                <div class="detailbox">
                    <div><img src="../../../assets/img/icon/trophy.png" /></div>
                    <span><span>{{ global.userInfo.rank.name }}</span></span>
                </div>
                <div class="detailbox">
                    <div><img src="../../../assets/img/icon/redeem.png" /></div>
                    <span><span>{{ global.userInfo.rp_balance }} RP</span></span>
                </div>
                <div class="detailbox">
                    <div><img src="../../../assets/img/icon/mission.png" /></div>
                    <span><span>10 missions</span></span>
                </div>
                <div class="clear-row"></div>
                <div class="summary">
                    <div class="text">
                        <div>Check in</div>
                        <span>3 challenge to claim your rewards</span>
                    </div>
                    <div class="step">
                        <div class="line"></div>
                        <div class="box completed"><span><i class="iconfont icon-check"></i></span></div>
                        <div class="box completed"><span><i class="iconfont icon-check"></i></span></div>
                        <div class="box completed"><span><i class="iconfont icon-check"></i></span></div>
                        <div class="box pending">
                            <div class="checkbox">
                                <div class="top">
                                    <span></span>
                                    <span></span>
                                </div>
                                <div class="text">Check In</div>
                            </div>
                        </div>
                        <div class="box">
                            <div class="day">
                                <span>Day</span>
                                <div>5</div>
                            </div>
                        </div>
                        <div class="box">
                            <div class="day">
                                <span>Day</span>
                                <div>6</div>
                            </div>
                        </div>
                        <div class="box">
                            <div class="claim">
                                <img src="../../../assets/img/gift.png" />
                                <div></div>
                                <span>Redeem</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clear-row"></div>
            </div>
        </div>
    </div>
    <div class="body">
        <div class="bodycover">
            <div class="list">
                <div class="box">
                    <div class="left">
                        <div>Time Left</div>
                        <span>8 hours</span>
                    </div>
                    <div class="cover">
                        <div></div>
                    </div>
                    <div class="content">
                        <div class="title">
                            <span>Mission</span>
                            <div>Finish Watching 3 videos</div>
                        </div>
                        <div class="button">
                            <span>Complete</span>
                        </div>
                    </div>
                </div>
                <div class="box">
                    <div class="left">
                        <div>Time Left</div>
                        <span>8 hours</span>
                    </div>
                    <div class="cover">
                        <div></div>
                    </div>
                    <div class="content">
                        <div class="title">
                            <span>Mission</span>
                            <div>Finish Watching 3 videos</div>
                        </div>
                        <div class="button">
                            <span>Complete</span>
                        </div>
                    </div>
                </div>
                <div class="box">
                    <div class="left">
                        <div>Time Left</div>
                        <span>8 hours</span>
                    </div>
                    <div class="cover">
                        <div></div>
                    </div>
                    <div class="content">
                        <div class="title">
                            <span>Mission</span>
                            <div>Finish Watching 3 videos</div>
                        </div>
                        <div class="button">
                            <span>Complete</span>
                        </div>
                    </div>
                </div>
                <div class="box">
                    <div class="left">
                        <div>Time Left</div>
                        <span>8 hours</span>
                    </div>
                    <div class="cover">
                        <div></div>
                    </div>
                    <div class="content">
                        <div class="title">
                            <span>Mission</span>
                            <div>Finish Watching 3 videos</div>
                        </div>
                        <div class="button">
                            <span>Complete</span>
                        </div>
                    </div>
                </div>

                <div class="clear-row"></div>
            </div>
            <div class="task">
                <div class="title">Challenge Task</div>
                <div class="ticket">
                    <div class="level" *ngIf="global.userInfo">
                        <div class="overlaybar"></div>
                        <div class="progressbar" [style]="{'background' : currentData ? ('conic-gradient(#5da3ff ' + currentData.percentage + '%, rgba(0,0,0,0) 0)') : '', 'opacity' : currentData ? '1' : '0'}"></div>
                        <div class="content">
                            <div class="contentcover">
                                <div class="text">
                                    <div class="img" [style]="{'background-image' : global.userInfo.rank.name === 'Rank 0' ? 'url(../../../assets/img/rank/0.png)' : ('url(' + (currentRank ? currentRank.img_url : '') + ')')}"></div>
                                    <div class="title" *ngIf="currentChallenge">Level {{ currentChallenge ? currentChallenge.level : '0' }}</div>
                                    <div class="desc" *ngIf="currentChallenge">Completed {{ currentData ? currentData.percentage : '0' }}%</div>
                                    <div class="title" *ngIf="!currentChallenge">{{ global.userInfo.rank.name }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="mark">
                            <div class="dot" [style]="{'transform' : currentData ? ('rotate(' + ((360*currentData.percentage) / 100) + 'deg)') : '', '-webkit-transform' : currentData ? ('rotate(' + ((360*currentData.percentage) / 100) + 'deg)') : ''}"><span></span></div>
                        </div>
                    </div>
                    <div class="space">
                        <div class="top"></div>
                        <div class="line"></div>
                        <div class="btm"></div>
                    </div>
                    <div class="list">
                        <div class="box" [class]="item.progress" *ngFor="let item of userChallengeData; index as index;" (click)="global.navigate('/quiz?id=' + item.id)">
                            <div class="boxcover">
                                <div class="level">Level {{ item.level }}</div>
                                <div class="title">{{ item.progress === 'incomplete' ? ('Complete Level ' + (item.level - 1) + ' to unlock') : item.title }}</div>
                                <div *ngIf="item.progress === 'ongoing'" class="ongoing">
                                    {{ currentData.percentage }}<span>%</span>
                                </div>
                                <div *ngIf="item.progress === 'incomplete'" class="lock"><i class="iconfont icon-password"></i></div>
                                <div *ngIf="item.progress === 'completed'" class="complete">Completed</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="other">
                <div class="backpack">
                    <div class="title">Backpack</div>
                    <div class="list">
                        <div class="box">
                            <img src="../../../assets/img/tickets.png" />
                            <div class="text">
                                <div class="event"><span>Event :</span><div>Autumn Tournament</div></div>
                                <div class="date"><span>Valid Date :</span><div>12 Oct 2023</div></div>
                            </div>
                            <div class="button">
                                <span>Use Ticket</span>
                            </div>
                        </div>
                        <div class="box">
                            <img src="../../../assets/img/tickets.png" />
                            <div class="text">
                                <div class="event"><span>Event :</span><div>Autumn Tournament</div></div>
                                <div class="date"><span>Valid Date :</span><div>12 Oct 2023</div></div>
                            </div>
                            <div class="button">
                                <span>Use Ticket</span>
                            </div>
                        </div>
                        <div class="box">
                            <img src="../../../assets/img/tickets.png" />
                            <div class="text">
                                <div class="event"><span>Event :</span><div>Autumn Tournament</div></div>
                                <div class="date"><span>Valid Date :</span><div>12 Oct 2023</div></div>
                            </div>
                            <div class="button">
                                <span>Use Ticket</span>
                            </div>
                        </div>
                        <div class="box">
                            <img src="../../../assets/img/tickets.png" />
                            <div class="text">
                                <div class="event"><span>Event :</span><div>Autumn Tournament</div></div>
                                <div class="date"><span>Valid Date :</span><div>12 Oct 2023</div></div>
                            </div>
                            <div class="button">
                                <span>Use Ticket</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tournament">
                    <div class="title">Tournament</div>
                    <div class="list">
                        <div class="box">
                            <div class="img"><img src="../../../assets/img/videocover.png" /></div>
                            <div class="content">
                                <div class="text">
                                    <div class="main">Autumn Season Tournament in Korea 2024</div>
                                    <div class="sub">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type</div>
                                    <div class="date">17th Aug 2024</div>
                                </div>
                            </div>
                        </div>
                        <div class="box">
                            <div class="img"><img src="../../../assets/img/videocover.png" /></div>
                            <div class="content">
                                <div class="text">
                                    <div class="main">Autumn Season Tournament in Korea 2024</div>
                                    <div class="sub">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type</div>
                                    <div class="date">17th Aug 2024</div>
                                </div>
                            </div>
                        </div>
                        <div class="box">
                            <div class="img"><img src="../../../assets/img/videocover.png" /></div>
                            <div class="content">
                                <div class="text">
                                    <div class="main">Autumn Season Tournament in Korea 2024</div>
                                    <div class="sub">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type</div>
                                    <div class="date">17th Aug 2024</div>
                                </div>
                            </div>
                        </div>
                        <div class="box">
                            <div class="img"><img src="../../../assets/img/videocover.png" /></div>
                            <div class="content">
                                <div class="text">
                                    <div class="main">Autumn Season Tournament in Korea 2024</div>
                                    <div class="sub">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type</div>
                                    <div class="date">17th Aug 2024</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clear-row"></div>
            </div>
        </div>
    </div>
</div>