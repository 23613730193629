<div class="logon">
    <div class="logoncover">
        <div class="box">
            <div class="logo"><img src="../../../assets/img/wpt-logo.png" /></div>
            <div class="text">We are so glad to have you on board</div>
            <div class="tab">
                <div [class]="{'active' : tabIndex === 0}" (click)="selectTab(0)">Mobile<span></span></div>
                <div [class]="{'active' : tabIndex === 1}" (click)="selectTab(1)">Email<span></span></div>
            </div>
            <div class="form" [class]="{'loading' : loader}">
                <div class="inputbox areacode" [class]="{'full' : tabIndex === 1}" (click)="openCodeList()">
                    <i class="iconfont icon-country"></i>
                    <input [(ngModel)]="formData.areacode" type="text" name="areacode" placeholder="Area Code" autocomplete="off" readonly />
                </div>
                <div class="inputbox phone" *ngIf="tabIndex === 0">
                    <i class="iconfont icon-phone"></i>
                    <input [(ngModel)]="formData.mobile" type="text" name="phone" placeholder="Phone Number" autocomplete="off" />
                </div>
                <div class="clear-row"></div>
                <div class="inputbox" *ngIf="tabIndex === 0">
                    <i class="iconfont icon-otp"></i>
                    <input [(ngModel)]="formData.yzm" type="text" name="otp" placeholder="Mobile OTP" autocomplete="off" />
                    <span [class]="{'disabled' : formData.mobile === '' || otpLoader, 'counting' : otpText !== 'Get OTP'}" (click)="getYzm()">{{ otpText }}</span>
                </div>
                <div class="clear-row"></div>
                <div class="inputbox" *ngIf="tabIndex === 1">
                    <i class="iconfont icon-email"></i>
                    <input [(ngModel)]="formData.email" type="text" name="email" placeholder="Email" autocomplete="off" />
                </div>
                <div class="clear-row"></div>
                <div class="inputbox">
                    <i class="iconfont icon-user"></i>
                    <input [(ngModel)]="formData.username" type="text" name="user" placeholder="Username" autocomplete="off" />
                </div>
                <div class="clear-row"></div>
                <div class="inputbox">
                    <i class="iconfont icon-check"></i>
                    <input [(ngModel)]="formData.name" type="text" name="user" placeholder="Name" autocomplete="off" />
                </div>
                <div class="clear-row"></div>
                <div class="inputbox">
                    <i class="iconfont icon-password"></i>
                    <input [(ngModel)]="formData.password" type="text" name="access" placeholder="Password" autocomplete="off" />
                </div>
                <div class="clear-row"></div>
            </div>
            <div class="button" [class]="{'loading' : loader}">
                <div (click)="register()">Sign Up</div>
            </div>
            <div class="note">By creating an account, you agree to our <span>Terms of Service</span> and <span>Privacy & Cookie Statement</span>.</div>
            <div class="login" (click)="global.navigate('/login')">
                <span>Already have account?</span>
                <div>Login Now</div>
            </div>
        </div>
    </div>
</div>

<areacode [status]="dialSelect" (onClose)="dialSelect = false" (onSelect)="selectDialCode($event)"></areacode>