<div class="videoplayer">

    <div class="watermark">
        <img src="../../../assets/img/logo.svg" />
    </div>
    <div class="player">
        <div class="control" [class]="typeData" *ngIf="playerStatus">
            <div class="playpause" *ngIf="typeData === 'video'" (click)="playPause()">
                <i class="iconfont" [class]="isPause ? 'icon-play' : 'icon-pause'"></i>
            </div>
            <div class="streaming" *ngIf="typeData === 'live'"><i class="iconfont icon-cam"></i>Live</div>
            <div class="progress" *ngIf="typeData === 'video'">
                <progress value="0" min="0" ></progress>
                <div class="progresscover">
                    <span class="progressbar"></span>
                    <span class="bufferbar"></span>
                </div>
            </div>
            <div class="duration" *ngIf="typeData === 'video'">{{ currentTime }} / {{ totalDuration }}</div>    
            <div class="action">
                <div class="reload" *ngIf="typeData === 'live'" (click)="refreshPage()"><i class="iconfont icon-replay"></i>Reload</div>
                <div class="volume">
                    <div>
                        <input type="range" min="0" max="100" [(ngModel)]="volumeValue" class="volumeslide" (input)="slideVolume($event)">
                    </div>
                    <span (click)="toggleVolume()">
                        <i class="iconfont" [class]="volumeState"></i>
                    </span>
                </div>
                <div class="fullscreen" (click)="toggleFullScreen()">
                    <i class="iconfont icon-fullscreen"></i>
                </div>
            </div>
        </div>
        <div class="screentab" [class]="{'volume-off' : !isVolume && isRefresh}" (dblclick)="dbclickScreen()">
            <div class="volcover" (click)="toggleVolume()">
                <div class="vol"><i class="iconfont icon-volume-off"></i></div>
            </div>
            <div class="ppcover" (click)="clickScreen()" *ngIf="typeData === 'video'">
                <span *ngIf="isPause"><i class="iconfont icon-pause"></i></span>
                <span *ngIf="!isPause"><i class="iconfont icon-play"></i></span>
            </div>
        </div>

        <!-- mobile tablet first time touch to turn on volume -->
        <div class="touchscreen" *ngIf="mobileTableScreen && !isVolume && isRefresh" (click)="tabScreen()">
            <span><i class="iconfont icon-volume-off"></i></span>
        </div>

        <div class="coverimg">
            <img src="../../../assets/img/videocover.png" />
        </div>

        <video id="video" #video
               [poster]="posterImg"
               autoplay  
               playsinline
               muted
               [controls]="false"
               allowfullscreen="true" 
               webkitallowfullscreen="true">
        </video>
    </div>
</div>