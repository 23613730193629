import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
// import * as flvjs from 'flv.js';
import HLS from 'hls.js';
import * as flvjs from 'flv.js';

import { GlobalStore } from '../../service/global.service';

@Component({
  selector: 'video-player',
  templateUrl: './videoplayer.component.html',
  styleUrls: ['./videoplayer.component.scss'],
})

export class VideoPlayer implements OnInit {
  @ViewChild('video', { static: true }) video!: ElementRef<HTMLVideoElement>;

  @Input()
  set data(val:any)
  {
    if(val)
    {
      this.initData(val);
    }
    else
    {
      var progress : any = document.querySelector('progress');
      if(progress)
      {
        progress.removeEventListener('click', () => {});
      }

      if(this.video)
      {
        this.video.nativeElement.pause();
        this.video.nativeElement.src = '';
        this.video.nativeElement.removeEventListener('loadedmetadata', () => {});
        this.video.nativeElement.removeEventListener('progress', () => {});
        this.video.nativeElement.removeEventListener('timeupdate', () => {});
        this.video.nativeElement.removeEventListener('ended', () => {});
      }

      if(this.hls)
      {
        this.hls.destroy();
        this.hls = null;
      }

      if(this.flv)
      {
        this.flv.destroy();
        this.flv = null;
      }
    }
  }

  'hls'           : any     = null;
  'flv'           : any     = null;

  'isRefresh'     : boolean = false;
  'isPause'       : boolean = false;
  'isVolume'      : boolean = false;
  'isTab'         : boolean = false;
  'volumeState'   : string  = 'icon-volume-low';
  'volumeValue'   : number = 0;
  'totalDuration' : any = 0;
  'currentTime'   : any = 0;
  'advanceData'   : any     = [
    {
      'name' : '透明度',
      'data' : ['20%', '40%', '60%', '80%'],
      'value': '60%'
    },
    {
      'name' : '弹幕字号',
      'data' : ['特小', '标准', '适中', '特大'],
      'value': '标准'
    },
    {
      'name' : '弹幕速度',
      'data' : ['特慢', '标准', '适中', '特快'],
      'value': '标准'
    }
  ];

  'urlData' : any = null;
  'typeData': string = '';
  'videoId' : string = '';
  'posterImg' : string = '../../../assets/img/videocover.png';
  'playerStatus' : boolean = false;
  'mobileTablet' : boolean = false;
  'mobileTableScreen' : boolean = false;

  constructor(
    public global: GlobalStore
  ) { }

  ngOnInit(): void {
  }
  ngOnDestroy(): void {
    
  }

  initData(data:any)
  { 
    let notFirstVisit : any = sessionStorage.getItem('notFirstVisit') || ''
    let entries = performance.getEntriesByType('navigation');
    entries.forEach((entry:any) => 
    {
      if (entry.type === 'reload' || entry.type === 'back_forward')
      {
        this.isRefresh = true;
      }
      else
      {
        if(!notFirstVisit)
        {
          this.isRefresh = true;
          sessionStorage.setItem('notFirstVisit', 'yes');
        }
      }
    });

    // check mobile tablet
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
    {
      this.mobileTableScreen = true;
      this.mobileTablet = true;
    }
    else
    {
      this.mobileTableScreen = false;
      this.mobileTablet = false;
    }

    this.hls = new HLS();
    this.urlData = JSON.parse(JSON.stringify(data.url));
    this.typeData = data.type;
    this.videoId = data.id;
    if(data.img)
    {
      this.posterImg = '';
      this.posterImg = data.img;
    }

    if(this.typeData === 'live' && (this.urlData.hls || this.urlData.flv))
    {
      this.playerStatus = true;
      if (HLS.isSupported())
      {
        this.hls.loadSource(this.urlData.hls);
        this.hls.attachMedia(this.video.nativeElement);

        this.video.nativeElement.controls = false;
        
        this.isVolume = !this.isRefresh;
        this.volumeValue = this.isVolume ? 100 : 0;
        this.video.nativeElement.volume = this.isVolume ? 1 : 0;
        this.video.nativeElement.muted = !this.isVolume;
        this.volumeState = this.volumeValue === 0 ? 'icon-volume-low' : 'icon-volume-high';

        this.video.nativeElement.play();
      }
      else if(this.video.nativeElement.canPlayType('application/vnd.apple.mpegurl'))
      {
        this.video.nativeElement.src = this.urlData.hls;
      
        this.video.nativeElement.load();
        this.video.nativeElement.controls = false;
        
        this.isVolume = !this.isRefresh;
        this.volumeValue = this.isVolume ? 100 : 0;
        this.video.nativeElement.volume = this.isVolume ? 1 : 0;
        this.video.nativeElement.muted = !this.isVolume;
        this.volumeState = this.volumeValue === 0 ? 'icon-volume-low' : 'icon-volume-high';

        this.video.nativeElement.play();
      }
      else 
      {
        this.flv = flvjs.default.createPlayer({
          type    : 'flv',
          cors    : true,   
          isLive  : true,      // 开启跨域访问
          url     : this.urlData.flv,   // 指定流链接
        },
        {
          enableStashBuffer: false,
          stashInitialSize : 128,
        });

        this.flv.attachMediaElement(this.video.nativeElement);
        this.flv.load();

        this.video.nativeElement.controls = false;

        this.isVolume = !this.isRefresh;
        this.volumeValue = this.isVolume ? 100 : 0;
        this.video.nativeElement.volume = this.isVolume ? 1 : 0;
        this.video.nativeElement.muted = !this.isVolume;
        this.volumeState = this.volumeValue === 0 ? 'icon-volume-low' : 'icon-volume-high';

        this.video.nativeElement.play();
      }
    }
    else if(this.typeData === 'video' && this.urlData.mp4)
    {
      this.playerStatus = true;
      this.video.nativeElement.src = this.urlData.mp4;
      
      this.video.nativeElement.load();
      this.video.nativeElement.controls = false;
      
      this.isVolume = !this.isRefresh;
      this.volumeValue = this.isVolume ? 100 : 0;
      this.video.nativeElement.volume = this.isVolume ? 1 : 0;
      this.video.nativeElement.muted = !this.isVolume;
      this.volumeState = this.volumeValue === 0 ? 'icon-volume-low' : 'icon-volume-high';

      this.video.nativeElement.play();

      this.video.nativeElement.addEventListener('loadstart', () => {
        this.initVideoPlayer();
      });
    }
    else
    {
      this.playerStatus = false;
    }
  }

  initVideoPlayer()
  {
    // Obtain handles to buttons and other elements
    var progress : any = document.querySelector('progress');
    var progressBar : any = document.querySelector('.progressbar');
    var bufferBar : any = document.querySelector('.bufferbar');

    this.video.nativeElement.addEventListener('loadedmetadata', () => {
      progress.setAttribute('max', this.video.nativeElement.duration);

      this.totalDuration = this.timeFormat(this.video.nativeElement.duration);
    });
    // check on buffering progress
    this.video.nativeElement.addEventListener('progress', (event:any) => {
      bufferBar.style.width = Math.floor(((this.video.nativeElement.currentTime + this.video.nativeElement.buffered.end(0)) / this.video.nativeElement.duration) * 100) + '%';
    });
    // As the video is playing, update the progress bar
    this.video.nativeElement.addEventListener('timeupdate', () => {
      // For mobile browsers, ensure that the progress element's max attribute is set
      if (!progress.getAttribute('max')) progress.setAttribute('max', this.video.nativeElement.duration);
      progress.value = this.video.nativeElement.currentTime;
      progressBar.style.width = Math.floor((this.video.nativeElement.currentTime / this.video.nativeElement.duration) * 100) + '%';
      
      this.currentTime = this.timeFormat(this.video.nativeElement.currentTime);
    });
    // React to the user clicking within the progress bar
    progress.addEventListener('click', (e:any) => {
      var p = e.offsetX / progress.offsetWidth
      this.video.nativeElement.currentTime = p * this.video.nativeElement.duration
    });

    this.video.nativeElement.addEventListener('ended', (e:any) => {
      console.warn(e)
    });
  }
  timeFormat(s:any)
  {
    var m :any = Math.floor(s / 60);
    m = (m >= 10) ? m : "0" + m;
    var s : any = Math.floor(s % 60);
    s = (s >= 10) ? s : "0" + s;
    return m + ":" + s;
  }
  playPause()
  {
    if (this.video.nativeElement.paused || this.video.nativeElement.ended)
    {
      this.isPause = false;
      this.video.nativeElement.play();
    }
    else
    {
      this.isPause = true;
      this.video.nativeElement.pause();
    }
  }
  slideVolume(event:any)
  {
    let value = event.target.value / 100;
    this.video.nativeElement.muted = value === 0;
    this.video.nativeElement.volume = value;
    this.isRefresh = false;
    if(value > 0 && value < 0.9)
    {
      this.volumeState = 'icon-volume-low';
    }
    else if(value >= 0.9)
    {
      this.volumeState = 'icon-volume-high';
    }
    else
    {
      this.volumeState = 'icon-volume-off';
    }

    if(value === 1)
    {
      this.isVolume = true;
    }
    if(value === 0)
    {
      this.isVolume = false;
    }
  }
  toggleVolume()
  {
    this.isVolume = !this.isVolume;
    if(this.isVolume)
    {
      this.volumeValue = 100;
      this.volumeState = 'icon-volume-high';
      this.video.nativeElement.volume = 1;
      this.video.nativeElement.muted = false;
    }
    else
    {
      this.volumeValue = 0;
      this.volumeState = 'icon-volume-off';
      this.video.nativeElement.volume = 0;
      this.video.nativeElement.muted = true;
    }
  }
  toggleFullScreen()
  {
    // detect mobile or pc
    // if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
    // {
    //   console.warn('mobile tablet')
    // }
    // else
    // {
    //   if (!document.fullscreenElement) 
    //   {
    //     document.documentElement.requestFullscreen();
    //   } 
    //   else if (document.exitFullscreen) 
    //   {
    //     document.exitFullscreen();
    //   }
    // }
    var elem : any = document.getElementById('video');
    console.warn(elem)
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) { /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE11 */
      elem.msRequestFullscreen();
    }
  }

  clickScreen()
  {
    this.isTab = !this.isTab;
    this.playPause();
  }
  dbclickScreen()
  {
    this.toggleFullScreen();
  }
  refreshPage()
  {
    window.location.href = `/watch?live=${this.videoId}`;
  }

  tabScreen()
  {
    this.video.nativeElement.controls = true;
    this.video.nativeElement.muted = false;
    this.video.nativeElement.volume = 1;
    this.mobileTableScreen = false;
  }

}
